
.site-search {
  border-radius: 4px;
  max-width: 570px;
  position: relative;

  &.is-open {
    .site-search__results {
      display: block;
    }
  }
  &:not(.is-open) {
    .button-input__input {
      padding-right: 8px;
    }
  }
}
.site-search.site-search--big {
  .button-input__input {
    @include text-h2;
  }
}
.site-search.site-search--carousel-slide {
  box-shadow: 1px 8px 16px 10px rgba(0, 0, 0, .20);
  max-width: none;

  .button-input {
    width: 100%;

    input {
      padding: 19px 39px;
    }
  }
  .site-search__submit-pill {
    display: none;
  }
}
.site-search.site-search--transparent,
.site-search.site-search--mprojectlistmap {
  .button-input {
    width: 100%;
  }
  .button-input__input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.34);
    border-radius: 0;
    background-color: transparent;

    &.search-input-open {
      border-bottom: 1px solid black;
    }
  }
  .site-search__button {
    display: none;
  }
}
.site-search.site-search--mprojectlistmap {
  .button-input__button-icon {
    height: 21px;
    width: 21px;
    max-height: 21px;
    max-width: 21px;
  }
  .button-input__input {
    @include text-h2;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      @include text-input-text;
      font-weight: 300;
      opacity: .34; /* Firefox */
    }
  }
}
.site-search__form {
  display: flex;
  width: 100%;
}
.site-search__field {
  flex: 1 1;

}
.site-search__button {
  flex: 0 0;
}
.site-search__submit-glass {

}
.site-search__submit-pill {
  margin-left: 10px;
}
.site-search__results {
  padding-top: 8px;
  max-height: 424px;
  display: none;
  position: absolute;
  z-index: 2;
  width: 100%;
}
.site-search__results-container {
  border: .5px solid #84BBC6;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .2), 0 24px 40px -40px rgba(0, 0, 0, .4);
  padding: 24px 40px 20px;
  background-color: white;
}
