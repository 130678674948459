.m-getinvolved {
  overflow: hidden;
}
.m-getinvolved__content {
  display: block;
  //margin-bottom: 44px;
  width:   100%;
}
.m-getinvolved__info {
  padding: 16px;
}
.m-getinvolved__cards {
  overflow:   hidden;
  overflow-x: auto;
}
.m-getinvolved__cards-container {
  display: flex;
  padding: 4px 8px;
}
.m-getinvolved__title {
  @include text-h1;
}
.m-getinvolved__description {
  @include text-txt-S;
}
.m-getinvolved__button {
  margin: 16px 0 0;
}
//
//tablet+
@include media-breakpoint-up(md) {
  .m-getinvolved {
    margin: 55px auto;
  }
  .m-getinvolved__content {
    display: flex;
    //margin-bottom: 50px;
  }
  .m-getinvolved__info {
    flex:    0 0 30%;
    padding: 100px 3% 0 10%;
  }
  .m-getinvolved__cards {
    flex: 0 0 70%;
  }
  .m-getinvolved__cards-container {
    padding: 8px 8px 26px;
  }
}
