.m-relatedprojects {
  //margin: 0 auto 48px;
  margin: 48px auto;

  .project-stub {
    margin: 12px 0;
  }
}
.m-relatedprojects__load-more {
  margin-top: 16px;
}
.m-relatedprojects__title {
  @include text-h3;
}
//
//tablet+
@include media-breakpoint-up(md) {
  .m-relatedprojects {
    margin: 48px auto 64px;

    .project-stub {
      margin: 28px 0 32px;
    }
    .m-relatedprojects__load-more {
      margin-top: 0;
    }
  }
}

