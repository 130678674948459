.page-project-details {
  width: 100%;
}
.page-project-details__close-icon {
  display: none;
}
.page-project-details__contact-us-tab {
  label {
    @include text-input-label;
  }
  input, textarea {
    @include input-base;
  }
  input[type=checkbox] {
    @include input-checkbox;
  }
}
.page-project-details__contact-us-title {
  font-size: 28px;
  font-weight: 500;
  margin: 4px 0 22px;
}
.page-project-details__contact-us-description {
  font-size: 14px;
  line-height: 21px;
}
.page-project-details__contact-information-title {
  @include text-h5;
  text-transform: uppercase;
  margin: 30px auto 8px;
}
.page-project-details__contact-information-description {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
.page-project-details__contact-submit {
  margin-top: 4px;
}
.page-project-details__feature-location__title {
  @include text-h5;
  margin: 0 0 10px;
}
.page-project-details__feature-location__item {
  @include text-links-sm;
  margin: 5px 0;
}
.page-project-details__feature-toggle {
  @include text-txt-R-link;
}
.page-project-details__feedback-add-description-title {
  @include text-h3
}
.page-project-details__feedback-add-description-subtitle {
  @include text-txt-S
}
.page-project-details__feedback-bar {
  display: none;
}
.page-project-details__feedback-bar-mobile {
  @include text-h3;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFC828;
  padding: 16px;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 30px;
}
.page-project-details__feedback-button-add {
  border: none;
  background: none;
  cursor: pointer;
  margin-right: -10px;
}
.page-project-details__feedback-send {
  margin-right: 10px;
}
.page-project-details__feedback-step-label {
  @include text-input-label;
  margin-top: 15px;
  display: inline-block;
}
.page-project-details__feedback-tab-add-comment {
  @include text-txt-R-link;
  margin: 14px 0 23px;
  display: inline-block;
  cursor: pointer;
}
.page-project-details__feedback-tab-add-comment-textarea {
  @include input-base;
  margin: 20px 0 10px;
  width: 100%;
}
.page-project-details__feedback-tab-description {
  margin-bottom: 13px;
}
.page-project-details__feedback-tab-intro {
  @include text-h3;
  margin: 10px 0;
}
.page-project-details__feedback-tab-intro-title {
  font-size: 28px;
  font-weight: 500;
}
.page-project-details__feedback-tab-intro-description {
  font-size: 14px;
  line-height: 1.5;
}
.page-project-details__feedback-tab-feature {
  margin-top: 16px;
}
.page-project-details__feedback-tab-feature-title {
  border-bottom: solid 2px #DD9405;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 1.2px;
  padding: 4px 8px 9px;
  text-transform: capitalize;
}
.page-project-details__feedback-tab-feature-item {
  padding: 4px 8px 4px;
  height: 57px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, .15);
}
.page-project-details__feedback-tab-feature-count {
  font-size: 24px;
  font-weight: normal;
  text-align: right;
}
.page-project-details__feedback-tab-feature-name {
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
}
.page-project-details__feedback-tab-features {
  display: none;
}
.page-project-details__feedback-tab-features--is-visible {
  display: block;
}
.page-project-details__feature-sub-tab {
  p {
    margin-top: 24px;
    font-size: 14px;
    line-height: 24px;
  }
}
.page-project-details__feature-sub-tab-img {
  width: auto;
  height: auto;
  background-size: cover;
  background-position: center;
  //margin-top:          14px;
}
.page-project-details__feature-location {
  margin-top: 32px;
}
.page-project-details__feature-location {
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  letter-spacing: 1.2px;
  margin-bottom: 16px;
}
.page-project-details__feature-location {
  font-size: 14px;
  font-weight: 500;
  color: #5085a6
}
.page-project-details__info {
  p {
    margin: 8px 0 0;
  }
}
.page-project-details__info-label {
  @include text-txt-S;
  font-weight: 500;
}
.page-project-details__info-title {
  @include text-h5;
  margin-bottom: 16px;
  margin-top: 30px;
}
.page-project-details__intro__subtitle {
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 1.2px;
  margin-bottom: 5px;
  font-weight: bold;
}
.page-project-details__intro-title {
  @include text-h2;
}
.page-project-details__intro-title-container {
  margin-bottom: 20px;
}
.page-project-details__intro-description {
  @include text-txt-R;
}
.page-project-details__legend {
  display: none;
}
.page-project-details__link-item {
  margin-top: 8px;
}
.page-project-details__listing {
  margin: 8px 0px 64px;
}
.page-project-details__load-more {
  margin-top: 10px;
}
.page-project-details__map-button {
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 12px;
  z-index: 2;
  font-size: 0;
  line-height: 0;
  color: transparent;
  width: 48px;
  height: 48px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .1);
}
.page-project-details__map-button--filtering {
  background-image: url(../img/icons/icon-menu.svg);
  background-size: 22px auto;
  left: 8px;
}
.page-project-details__map-button--close {
  background-image: url(../img/icons/icon-close.svg);
  background-size: 17px auto;
  left: 8px;
  //left:64px;
}
.page-project-details__map-iframe {
  border: 0;
  position: static;
  flex: auto;
  min-height: 334px;
}
.page-project-details__map-iframe-container {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #EDF0F2;

  .page-project-details__feedback-tab {
    padding: 15px 15px 72px;
    background-color: white;
  }
}
.page-project-details__mobile-feedback {
  padding: 12px 15px;
  background-color: #FFC828;
  align-items: center;
  display: none;
}
.page-project-details__mobile-feedback-description {
  @include text-txt-R-bold;
  padding-right: 20px;
}
.page-project-details__mobile-feedback-cancel {
  margin-left: auto;
}
.page-project-details__news-events-tab {
  .container {
    margin: 0 -15px;
  }
}
.page-project-details__results-link {
  @include text-txt-R-link;
  display: block;
  margin: 0 0 24px 16px;
}
.page-project-details__search {
  margin-bottom: 0;
}
.page-project-details__tab-menu {
  flex: 0 0 170px;
  position: relative;
  z-index: 1;
}
.page-project-details__tab-menu-tabs {
  background-color: #E8E9EB;
  padding: 8px;
  height: 100%;
}
.page-project-details__tab {
  align-items: flex-end;
  border-bottom-color: rgba(black, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  cursor: pointer;
  display: block;
  margin: 0 8px;
  padding: 19px 0;
  transition: #{$transition-fast} padding, #{$transition-fast} margin;

  &:last-of-type {
    border-bottom-width: 0;
  }

  &.active {
    background-color: #DD9405;
    border-radius: 5px;
    color: white;
    margin: 0;
    outline: 1px solid #E8E9EB;
    padding: 20px 10px;

    .page-project-details__tab-icon {
      filter: invert(1) sepia(80) saturate(0) hue-rotate(0);
    }
  }
  &.sub-active {
    background-color: #ff8563;
    background-image: url(../img/icons/icon-minus.svg);
    background-position: right 8px center;
    background-repeat: no-repeat;
    border-radius: 5px;
    color: black;
    margin: 5px 0;
    padding: 23px 10px;
  }
}
.page-project-details__tab--hide-mobile {
  display: none;
}
.page-project-details__tab--subtab {
  background-image: url(../img/icons/icon-plus.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding: 24px 0;

  .page-project-details__tab-title {
    margin-left: 0;
  }
}
.page-project-details__tab-back {
  position: relative;
  margin-top: -30px;
  width: 16px;
}
.page-project-details__tab-back--black {
  filter: invert(1);
  border-color: #000;
}
.page-project-details__tab-content-title {
  @include text-h2;
  margin: 5px 0 5px;
}
.page-project-details__tab-content-description {
  @include text-txt-S;
  margin: 5px 0 25px;
}
.page-project-details__tab-icon {
  width: 24px;
  //height: 25px;
  height: auto;
}
.page-project-details__tab-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-left: 8px;
}
.page-project-details__tab-title-container {
  display: flex;
  height: 100%;
  align-items: center;
}
.page-project-details__tab-menu-buttons {
  display: none;
}
.page-project-details__tags {
  margin: 17px 0 12px;
  display: flex;

  .m-listtag__title {
    @include text-txt-S;
    font-weight: 500;
  }
  .m-listtag__tag {
    margin: 0 0 5px 8px;
  }
}
.page-project-details__toolbar {
  margin-top: 23px;
  border: 0px solid transparent;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.page-project-details__toolbar-buttons {
  display: none;
}
.page-project-details__toolbar-clear-all {
  display: none;
}
.page-project-details__toolbar-close {
  display: none;
}
.page-project-details__toolbar-col-buttons {
  display: none;
}
.page-project-details__toolbar-col-filter {
  flex: 1 0 100px;
}
.page-project-details__toolbar-col-filter-title {
  display: none;
}
.page-project-details__toolbar-col-programs {
  display: none;
}
.page-project-details__toolbar-col-statuses {
  display: none;
}
.page-project-details__toolbar-col-toggle {
  flex: 0 0 250px;
}
.page-project-details__toolbar-col-types {
  display: none;
}
.page-project-details__toolbar-filter {
  @include text-txt-R-link;
}
.page-project-details__toolbar-select {
  width: 100%;
}
.page-project-details__toolbar-select-title {
  @include text-input-label;
  margin-bottom: 5px;
}
.page-project-details__toolbar--map-view {
  background-color: #EDF0F2;
  border-color: $c-blue-input-light;
}
.page-project-details__toolbar--fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
  margin: 0;
  padding: 0;

  .select {
    .select__cover {
      display: none;
    }
    .flyout {
      display: none;
    }
  }
  .page-project-details__toolbar-buttons {
    flex-direction: row;
    width: 100%;
    display: flex;
    flex: 1;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid $c-blue-input-light;

    .tg-button {
      margin: 0 3px;
    }
  }
  .page-project-details__toolbar-container {
    > .row {
      flex-direction: column;
    }
  }
  .page-project-details__toolbar-clear-all {
    @include text-txt-R-link;
    display: inline-block;
    margin: 0 30px;
  }
  .page-project-details__toolbar-close {
    display: block;
    //position:            absolute;
    //top:                 0;
    //right:               0;
    width: 64px;
    height: 64px;
    background-color: transparent;
    background-image: url(../img/icons/icon-close.svg);
    background-size: 24px auto;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 0;
    color: transparent;
    border: 0;
    margin-left: auto;
    padding: 0;
  }
  .page-project-details__toolbar-col-filter {
    display: none;
  }
  .page-project-details__toolbar-col-filter-title {
    display: block;
    @include text-h5;
    text-transform: uppercase;
    margin: 0 0 30px;
  }
  .page-project-details__toolbar-col-programs {
    display: block;
    margin: 0 0 20px;
  }
  .page-project-details__toolbar-col-statuses {
    display: block;
  }
  .page-project-details__toolbar-col-toggle {
    display: none;
  }
  .page-project-details__toolbar-col-types {
    display: block;
    margin: 0 0 30px;
  }
}
.page-project-details__view-toggle {
  display: inline-block;
  border: 1px solid $c-blue-input;
  border-radius: 100px;
  padding: 3px;
  background-color: white;
}
.page-project-details__view-toggle-button {
  @include text-button;
  padding: 6px 11px;
}
.page-project-details__view-map {
  background: black;
  color: white;
  display: block;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  margin: 0 0 7px 0;
  border: 0;
}
.page-project-details__map-icon {
  filter: invert(1);
  margin: 0 10px 5px 0;
}
.page-project-details__tab-content-container {
  // overflow-y: auto;
  //overflow: scroll;

  iframe {
    max-width: 100%;
  }
}
//
//
//tiny phone (iphone 5 or smaller)
@media screen and (max-width: 374px) {
  .page-project-details__map-iframe-container {
    .page-project-details__feedback-tab {
      padding: 15px;
    }
  }
}
//
// smaller than tablet
@include media-breakpoint-down(md) {
  .page-project-details__line-contact {
    margin-bottom: 16px;
    display: block;
  }
  .page-project-details__line-info {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 21px;

    .navigationlink__link {
      .navigationlink__title {
        text-decoration: underline;
      }
    }
  }
  .page-project-details__map {
    //display: none;

    &.page-project-details__map--is-open {
      display: flex;

      .page-project-details__map-iframe-container {
        display: flex;
        overflow: auto;
      }
      .page-project-details__mobile-feedback {
        display: flex;
      }
      .page-project-details__tab-menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        border: 0;
        display: none;

        &.page-project-details__tab-menu--filters-open {
          display: block;
        }
      }
      .page-project-details__tab-content-container {
        background-color: #fdfdfd;
        padding: 12px 8px 20px;
        border-radius: 4px;
        margin-top: 4px;
      }
    }
  }
  .page-project-details__tab-content {
    background: white;
    padding: 8px;
    margin-top: 4px;
    border-radius: 4px;

    .subtitle {
      @include text-h5;
      margin-top: 12px;
    }
  }
  .page-project-details__tab-menu-buttons {
    flex-direction: row;
    width: 100%;
    display: flex;
    flex: 1;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid $c-blue-input-light;
    background-color: white;

    .tg-button {
      margin: 0 3px;
    }
  }
}
//
//tablet+
@include media-breakpoint-up(lg) {
  .page-project-details__close-icon {
    height: 0;
    top: 18px;
    right: 10px;
    left: initial;
    display: flex;
    z-index: 1;
  }
  .page-project-details__feature-sub-tab-img {
    width: 480px;
    height: 320px;
  }
  .page-project-details__tab-about {
  }
  .page-project-details__feature-toggle {
    display: none;
  }
  .page-project-details__feedback-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFC828;
    display: flex;
    padding: 8px 8px 8px 48px;
    align-items: center;
  }
  .page-project-details__feedback-arrow {
    flex: auto;
    border-bottom: 2px solid black;
    position: relative;
    margin-right: 30px;

    &::after {
      content: "\A0";
      width: 8px;
      height: 14px;
      position: absolute;
      right: -1px;
      top: 1px;
      transform: translateY(-50%);
      background-image: url(../img/icons/icon-caret-right.svg);
    }
  }
  .page-project-details__feedback-bar-description {
    flex: auto 0;
    padding: 8px 24px 8px 0;
  }
  .page-project-details__feedback-bar-description-title {
    @include text-h3;
  }
  .page-project-details__feedback-bar-description-subtitle {
    @include text-txt-XS;
  }
  .page-project-details__feedback-bar-mobile {
    display: none;
  }
  .page-project-details__feedback-button-add {
    padding: 0 0 2px;
    margin: 0;
  }
  .page-project-details__feedback-button-cancel {
    margin-left: auto;
  }
  .page-project-details__feedback-followup-mobile {
    display: none;
  }
  .page-project-details__feedback-tab-features {
    display: block;
  }
  .page-project-details__feedback-tab-intro {
    @include text-h2;
    margin: 0;
  }
  .page-project-details__info-description {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: #5085A6;
  }
  .page-project-details__info-label {
    font-size: 12px;
  }
  .page-project-details__legend {
    display: block;
    background-color: #DBDDDE;
    padding: 10.5px 0;
    //left: -11px;
    position: relative;
    margin-top: 15px;
    border-radius: 4px;
  }
  .page-project-details__legend-item {
    margin-left: 8px;
    margin-bottom: 11px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .page-project-details__legend-icon {
  }
  .page-project-details__legend-title {
    margin-left: 7px;
    @include text-base;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }
  .page-project-details__line-contact {
    font-size: 14px;
    line-height: 1.5;
  }
  .page-project-details__line-info {
    font-size: 12px;
    line-height: 15px;
    //color: #5085a6;
    //
    //b {
    //  font-weight: 500;
    //  color: black;
    //}
  }
  .page-project-details__listing {
    margin: -25px 0 55px;
  }
  .page-project-details__load-more {
    margin-top: 0;
  }
  .page-project-details__map {
    display: flex;
    min-height: 800px;
    margin: 48px 0;
    border-top: 1px solid #B8D7DE;
    border-bottom: 1px solid #B8D7DE;
  }
  .page-project-details__map-button {
    display: none;
  }
  .page-project-details__map-iframe-container {
    display: block;
    position: relative;
    flex: auto;
    background-color: #EDF0F2;
    z-index: auto;
    height: auto;
  }
  .page-project-details__map-iframe {
    height: 100%;
    width: 100%;
  }
  .page-project-details__search {
    margin-bottom: 48px;
  }
  .page-project-details__tab {
    margin: 0 8px;

    &.active {
      margin: 0;
    }
    &.sub-active {
      background-image: none;
      padding: 8px 10px;
    }
    &:last-of-type {
      border-bottom-width: 1px;
    }
  }
  .page-project-details__tab--hide-mobile {
    display: flex;
  }
  .page-project-details__tab--show-back-desktop {
    .page-project-details__tab-back {
      display: inline-block;
    }
  }
  .page-project-details__tab--subtab {
    background-image: none;
    padding: 8px 0;
  }
  .page-project-details__tab-back {
    display: none;
  }
  .page-project-details__tab-content-container {
    display: block;
    //width: 630px;
    background-color: #fdfdfd;
    //padding: 25px 40px 25px 30px;
    //position: absolute;
    //top: 0;
    //left: 100%;
    height: 100%;
    //width: 550px;
    background: rgba(255, 255, 255, .88);
    animation: 1s ease-out 0s 1 slideInFromLeft;
    position: relative;
    top: auto;
    left: auto;
    padding: 0;
    width: 100%;
    flex: 1 1 550px;
  }
  .page-project-details__tab-content {
    animation: 1s opacityFadeIn, 1s translateSlideUp;
    position: absolute;
    width: 550px;
    height: 100%;
    padding: 25px 20px 25px 30px;
    right: 0;
    overflow: auto;

  }
  .page-project-details__tab-content-title {
    margin: 15px 0 5px;
  }
  .page-project-details__tab-menu {
    display: flex;
    width: auto;
    flex-direction: row;
    width: 170px;
    flex: 0 0 170px;
    transition: #{$transition-slow} flex, #{$transition-slow} max-width;
    display:none;
  }
  .page-project-details__tab-menu--map-ready{
    display:flex;
  }
  .page-project-details__tab-menu--is-open {
    max-width: 720px;
    flex: 0 0 720px;
  }
  .page-project-details__tab-menu-tabs {
    border-right: 1px solid #B8D7DE;
    z-index: 1;
    position: relative;
    flex: 0 0 170px;
    width: 170px;
    padding: 12px;
  }
  .page-project-details__tags {
    margin-top: 18px;
    margin-bottom: 12px;
  }
  .page-project-details__toolbar {
    margin-top: 48px;
    padding: 20px 0;
  }
  .page-project-details__view-map {
    display: none;
  }
}
//
//small desktop+
@include media-breakpoint-up(lg) {
  .page-project-details__intro {
    margin-bottom: 48px;
  }
  .page-project-details__intro-title {
    @include text-h1;
  }
  .page-project-details__toolbar-col-filter {
    display: none;
  }
  .page-project-details__toolbar-col-programs {
    flex: 1 0 200px;
    display: block;
  }
  .page-project-details__toolbar-col-statuses {
    flex: 1 0 200px;
    display: block;
  }
  .page-project-details__toolbar-col-toggle {
    margin-left: auto;
  }
  .page-project-details__toolbar-col-types {
    flex: 0 0 375px;
    display: block;
    //text-align: center;
  }
}


.project-detail-warning-link {
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.78);

  &:hover {
    color: rgba(0, 0, 0, 0.78);
  }
}
