//.m-latest-events--sidebar {
//  margin: 48px auto;
//}
.m-latest-events__title {
  font-family:    'Roboto';
  font-size:      12px;
  font-weight:    bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
.m-latest-events {
  .navigation-link {
    font-family: 'Roboto';
    font-size:   12px;
    color:       rgba(0, 0, 0, 0.78);
  }
}
.m-latest-events__view-all {
  margin: 0px auto 32px;
}
.m-latest-events--border-top {
  &.m-latest-events--horizontal {
    .m-latest-events__title {
      border-top: 1px solid $c-separator;
      padding:    22px 0 18px;
    }
  }
}
.m-latest-events--green {
  background-color: #E6F0EF;

  .event-item {
    border: 0;
  }

  &.m-latest-events--horizontal {
    .m-latest-events__title {
      padding-top:    48px;
      padding-bottom: 10px;
    }
    .events__listing {
      .event-item {
        .event-item__title {
          @include text-h4-link;
        }
        .event-item__description {
          display: block;
        }
      }
    }
  }
}
.m-latest-events--horizontal {
  .m-latest-events__title {
    @include text-h3;
    text-transform: none;
    margin:         0;
    padding:        32px 0;
  }
  .events__listing {
    .event-item {
      .event-item__calendar {
        color: black;
      }
      .event-item__content {
        flex:         1 1 298px;
        padding-left: 12px;
      }
      .event-item__description {
        display: none;
      }
      .event-item__content {
        .event-item__title {
          @include text-h4-link;
          padding-bottom: 0;
        }
      }
    }
  }
}
.m-latest-events {
  .event-item {

    .event-item__calendar {
      padding: 0 0 7px;

      .event-item__day {
        padding-top: 8px;
      }
    }

    .event-item__content {

      .event-item__title {
        font-weight: 400;
        font-size:   14px;
      }
    }
  }
}
//
//desktop+
@include media-breakpoint-up(lg) {
  .m-latest-events__view-all {
    margin: 32px auto 48px;
  }
  .m-latest-events--horizontal {
    .events__listing {
      flex-direction: row;

      .events__listing-item {
        flex:      0 0 33%;
        max-width: 33%;
      }
      .event-item {
        border-bottom: none;

        .event-item__description {
          display: block;
        }
      }
    }
  }
}
