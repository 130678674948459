.navigationlink {
  @include text-list;
  margin: 0 0 10px;
  display: block;

  &:hover {
    text-decoration: none;
  }
}
.navigationlink__link {
  @include text-list;

  &a, &button {
    text-decoration: none;

    .navigationlink__title {
      cursor: pointer;
    }
  }
}
.navigationlink__description {
  @include text-txt-XS-lighter;
  margin: 16px 0 0;
  word-wrap: break-word;
}
.navigationlink--link {
  .navigationlink__link {
    @include text-txt-R-link;
  }
}
.navigationlink--blue-outline {
  @include navigationlink--blue-outline;
  text-decoration: none;

  .navigationlink__link {
    .navigationlink__title {
      font-size: 12px;
    }
  }
}
.navigationlink--button {
  margin-bottom: 4px;

  .navigationlink__title {
    @include text-h4;
    display: block;
    background-color: rgba(255, 255, 255, .5);
    padding: 16px;
  }
}
.navigationlink--button-pill {
  border-radius: 100px;
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid white;
  margin: 5px 0 20px;

  .navigationlink__title {
    @include text-button-centered-reversed;
    color: white;
  }
}
.navigationlink--card {
  margin-bottom: 0;
}
.navigationlink--inline {
  display: inline-block;

  .navigationlink__description {
    display: inline-block;
  }
  .navigationlink__title {
    display: inline-block;
  }
}
.navigationlink--selected {
  .navigationlink__title {
    font-weight: 500;
    color: $c-blue-link;
  }
}
.navigationlink--disabled {
  .navigationlink__link {
    &:hover {
      text-decoration: none;
      cursor: default;
    }
  }
  .navigationlink__title {
    color: rgba(0, 0, 0, .3);
  }
}
.navigationlink--text-bold-uppercase {
  margin: 16px 0;

  .navigationlink__title {
    @include text-footer-section;
    color: white;
    text-transform: uppercase;
  }
}
//
//desktop+
@include media-breakpoint-up(xl) {
  .navigationlink__title {
    transition: #{$transition-medium} all ease-in-out;
  }
  .navigationlink__link {
    transition: #{$transition-medium} all ease-in-out;

    &:hover {
      color: black;
      text-decoration: underline;

      .navigationlink__title {
        border-color: black;
      }
    }
  }
  .navigationlink--button {
    .navigationlink__title {
      &:hover {
        color: white;
        background-color: #3D5741;
        text-decoration: none;
      }
    }
    .navigationlink__link {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .navigationlink--blue-outline {
    &:hover {
      text-decoration: none;
    }
  }
}
