.m-carousel {
  position:      relative;
  margin-bottom: 80px;
  overflow:      visible;

  .container--coupling {
    position: relative;
  }
  .m-carousel__carousel-container {
    width: 100%;
  }
  .m-carousel__carousel-container > div {
    @include media-breakpoint-up(lg) {
      //position: absolute;
      //left:     0;
      //top:      0;
      //width:    100%;
      //height:   100%;
    }
  }
  .carousel.carousel-slider {
    overflow: visible;

    @include media-breakpoint-up(lg) {
      //position: absolute;
      //left:     0;
      //top:      0;
      //width:    100%;
      //height:   100%;
      //overflow: visible;
    }

    .control-arrow {
      position:            absolute;
      width:               16px;
      height:              16px;
      background-image:    url(../img/icon-arrow-right-black.png);
      background-size:     16px auto;
      background-repeat:   no-repeat;
      background-position: center center;
      color:               transparent;
      font-size:           0;
      opacity:             .8;
      top:                 100%;
      left:                auto;
      right:               17px;
      bottom:              auto;
      padding:             15px;
      margin-top:          12px;
      display:             inline-block;
      box-sizing:          content-box;

      &:before {
        display: none;
      }
      &:hover {
        background-color: transparent;
        opacity:          1;
      }

      &.control-prev {
        //transform: translate3d(583px, 20px, 0) rotate(180deg);
        transform: rotate(180deg);
        right:     65px;

        @include media-breakpoint-up(lg) {
          right: 185px;
        }
      }
      &.control-next {
        border-left: 2px solid $c-separator;
        //transform:   translate3d(615px, 20px, 0);
      }
      &.control-disabled {
        display: block;
        opacity: .25;
        //opacity: 1;
      }

      @include media-breakpoint-up(lg) {
        right:      137px;
        margin-top: 30px;
      }
    }
  }
  .thumbs-wrapper {
    display: none;
  }
  .slide {
    background: transparent;
    min-height: 500px;

    @include media-breakpoint-up(lg) {
      min-height: 915px;
    }
  }
  .control-dots {
    top:     100%;
    bottom:  auto;
    height:  auto;
    padding: 0;
    margin:  18px 0 0;

    .dot {
      width:         20px;
      height:        4px;
      border-radius: 100px;
      border:        1px solid $c-separator;
      box-shadow:    none;
      box-sizing:    content-box;
      margin:        0 2px;
      opacity:       1;

      &:hover {
        background-color: #FFAD7E;
        border-color:     #FFAD7E;
      }
      &.selected {
        background-color: #FF8563;
        border-color:     #FF8563;
      }
    }

    @include media-breakpoint-up(lg) {
      margin: 34px 0 0;
    }
  }
  .carousel-status {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 90px;
  }
}
.m-carousel__background {
  //min-height:          500px;
  height:              100%;
  width:               100%;
  background-color:    #D7C4A6;
  background-size:     cover;
  background-position: top center;

  @include media-breakpoint-up(lg) {
    //min-height: 916px;
    //min-height: 886px;
  }
}
.m-carousel__background-img {
  width:               100%;
  height:              100%;
  background-size:     cover;
  background-position: bottom center;
  position:            absolute;
  top:                 0;
  left:                0;
}
.m-carousel__background-video-container {
  .m-carousel__background-video {
    display: none;
  }
}
//
//desktop+
@include media-breakpoint-up(lg) {
  .m-carousel__background-video-container {
    position: absolute;
    top:      0;
    bottom:   0;
    left:     0;
    right:    0;
    height:   100%;
    overflow: hidden;

    .m-carousel__background-video {
      display:             block;
      width:               100%;
      height:              100%;
      background-size:     cover;
      background-position: bottom center;
      position:            absolute;
      top:                 0;
      left:                0;
      height:              auto;
      min-height:          100%;
      position:            absolute;
      top:                 50%;
      left:                50%;
      transform:           translate3d(-50%, -50%, 0);
      //height:     100%;
      width:               auto;
      min-width:           100%;
    }
    .m-carousel__background-img {
      //display: none;
      z-index: -1;
    }
  }
}
