.latest-related-events__title {
  font-family:    'Roboto';
  font-size:      12px;
  font-weight:    bold;
  text-transform: uppercase;
}
.latest-related-events {
  .navigation-link {
    font-family:         'Roboto';
    font-size:           12px;
    color:               rgba(0, 0, 0, 0.78);
    background-image:    url(../img/icon-arrow-right-black.png);
    background-size:     12px 9px;
    background-repeat:   no-repeat;
    background-position: left 10px center;
    padding-left:        15px;
  }
}
