.m-list-follow {
  font-size:   12px;
  font-weight: bold;
}
.m-list-follow__link {
  color: rgba(0, 0, 0, 0.78);

  .button__icon {
    margin-right: 16px;
    font-size:    16px;
  }
}
.m-list-follow__title {
  @include text-h5;
  text-transform: uppercase;
  margin-right:   16px;
}
.m-list-follow.m-list-follow--title-line {
  .m-list-follow__title {
    display:       block;
    margin-right:  0;
    margin-bottom: 15px;
  }
}
