
html, body, #app, .page, .page__sticky {
  height: 100%;
}
.page__sticky {
  display: flex;
  flex-direction: column;
}
.page__sticky-body {
  flex: 1 0 auto;
}
body {
  top: 0 !important;;

  > .skiptranslate {
    line-height: 0;

    .goog-te-banner-frame {
      position: static;
    }
  }

  #app {
    position: relative;
  }
}

@mixin reset_list() {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}
// A map of breakpoints.
$breakpoints: (
  xs: 576px, //phone
  sm: 768px, //phablet
  md: 992px, //tablet
  lg: 1200px //desktop
);
$c-white-transparent: rgba(255, 255, 255, .78);
$c-black-transparent: rgba(0, 0, 0, .78);
$c-blue: #10293F;
$c-blue-link: #5085A6;
$c-blue-input: rgba(132, 187, 198, .78);
$c-blue-input-light: rgba(132, 187, 198, .58);
$c-error: #EF3E42;
$c-error-bg: rgba(239, 62, 66, .08);
$c-error-bg-solid: #FEF0F0;
$c-active: #FF8563;
$c-focused-bg: rgba(255, 133, 99, .06);
$c-focused-bg-solid: #F6F3F2;
$c-separator: rgba(0, 0, 0, .15);
$container-width-percent-desktop: percentage(1170/1440);
$transition-fast: .2s;
$transition-medium: .3s;
$transition-slow: .5s;

@mixin reset_list() {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}
@mixin text-base() {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  color: $c-black-transparent;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}
@mixin text-h1() {
  @include text-base;
  font-weight: 500;
  font-size: 36px;
  letter-spacing: -.5px;
  line-height: 45px;
}
@mixin text-h1-accent() {
  @include text-base;
  color: $c-blue;
  font-weight: bold;
  font-size: 57px;
  letter-spacing: -2px;
  line-height: 60px;

  @include media-breakpoint-up(xl) {
    font-size: 57px;
    letter-spacing: -2px;
    line-height: 60px;
  }
}
@mixin text-h2() {
  @include text-base;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;

  @include media-breakpoint-up(xl) {
  }
}
@mixin text-h3() {
  @include text-base;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}
@mixin text-h4() {
  @include text-base;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}
@mixin text-h4-accent() {
  @include text-h4;
  color: $c-blue;
}
@mixin text-h4-link() {
  @include text-h4;
  color: $c-blue-link;
}
@mixin text-h5() {
  @include text-base;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1.2px;
  line-height: 15px;
}
@mixin text-txt-R() {
  @include text-base;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
@mixin text-txt-R-link() {
  @include text-txt-R;
  color: rgba(0, 0, 0, .58);
  color: #5085A6;
  font-size: 16px;
  line-height: 24px;
  color: #5085A6;
  font-weight: 500;
}
@mixin text-txt-R-bold() {
  @include text-base;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
    letter-spacing: normal;
    line-height: 24px;
  }
}
@mixin text-txt-XS() {
  @include text-base;
  font-size: 12px;
  line-height: 18px;
}
@mixin text-txt-XS-lighter() {
  @include text-base;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, .58);
}
@mixin text-txt-S() {
  @include text-base;
  font-size: 14px;
  line-height: 21px;
}
@mixin text-txt-M() {
  @include text-base;
  font-size: 18px;
  line-height: 28px;
}
@mixin text-txt-M2() {
  @include text-base;
  font-size: 19px;
  line-height: 20px;
}
@mixin text-txt-L() {
  @include text-base;
  font-size: 20px;
  line-height: 32px;
}
@mixin text-button() {
  @include text-base;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
@mixin text-links() {
  @include text-base;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: $c-blue-link;
}
@mixin text-links-sm() {
  @include text-base;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: $c-blue-link;
}
@mixin text-button-centered {
  @include text-base;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-button-centered-reversed {
  @include text-base;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-button-label-reversed {
  @include text-base;
  color: white;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -.4px;
  line-height: 20px;
}
@mixin text-button-sm {
  @include text-base;
  font-size: 12px;
  line-height: 15px;
}
@mixin text-card-title() {
  @include text-base;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: -.5px;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    font-size: 32px;
    line-height: 36px;
  }
}
@mixin text-display() {
  @include text-base;
  font-weight: 500;
  font-size: 48px;
  letter-spacing: -1px;
  line-height: 60px;
}
@mixin text-filter() {
  @include text-base;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
@mixin text-filter-section() {
  @include text-base;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  letter-spacing: -.1px;
}
@mixin text-filter-active() {
  @include text-filter;
  color: $c-blue;
}
@mixin text-footer-link() {
  @include text-base;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  @include media-breakpoint-up(xl) {
    font-size: 12px;
  }
}
@mixin text-footer-section() {
  @include text-base;
  color: white;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
@mixin text-icon-label() {
  @include text-base;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: .5px;
  line-height: 11px;
}
@mixin text-input-hint() {
  @include text-base;
  color: rgba(0, 0, 0, .34);
  font-size: 16px;
  line-height: 24px;
}
@mixin text-input-label() {
  @include text-base;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
@mixin text-input-text() {
  @include text-base;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
@mixin text-list-menu() {
  @include text-base;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-list {
  @include text-base;
  font-size: 14px;
  line-height: 18px;
}
@mixin text-list-hover {
  @include text-base;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  color: #000;
}
@mixin text-main-nav() {
  @include text-base;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 13px;
    letter-spacing: .5px;
    line-height: 15px;
    font-weight: 500;
  }
}
@mixin text-nav() {
  @include text-base;
  color: white;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
}
@mixin text-nav-icon-label() {
  @include text-base;
  font-weight: bold;
  font-size: 9px;
  letter-spacing: .36px;
  line-height: 16px;
}
@mixin text-txt-link() {
  @include text-base;
  color: $c-blue;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
@mixin text-product-label() {
  @include text-base;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
}
@mixin text-product-label-S() {
  @include text-base;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
@mixin button--white-outline {
  @include text-button-label-reversed;
  //vertical-align: middle;
  width: auto;
  height: auto;
  padding: 12px 24px;
  flex-shrink: 0;
  background: white;
  color: black;
  outline: 1px solid rgba(0, 0, 0, .34);
  border: none;

  .button__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    max-width: 18px;
    max-height: 18px;
  }

  &:hover, &:visited, &:active {
    color: black;
  }
}
@mixin text-display-bold() {
  @include text-base;
  color: black;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1px;
}
@mixin text-google-translate() {
  @include text-base;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
}
.text-base {
  @include text-base;
}
.text-h1 {
  @include text-h1;
}
.text-h1-accent {
  @include text-h1-accent;
}
.text-h2 {
  @include text-h2;
}
.text-h3 {
  @include text-h3;
}
.text-h4 {
  @include text-h4;
}
.text-h4-accent {
  @include text-h4-accent;
}
.text-h5 {
  @include text-h5;
}
.text-txt-R {
  @include text-txt-R;
}
.text-txt-R-link {
  @include text-txt-R-link;
}
.text-txt-R-bold {
  @include text-txt-R-bold;
}
.text-txt-XS {
  @include text-txt-XS;
}
.text-txt-S {
  @include text-txt-S;
}
.text-txt-M {
  @include text-txt-M;
}
.text-txt-M2 {
  @include text-txt-M2;
}
.text-txt-L {
  @include text-txt-L;
}
.text-display-bold {
  @include text-display-bold;
}
.text-button {
  @include text-button;
}
.text-button-label-reversed {
  @include text-button-label-reversed;
}
.text-button-centered-reversed {
  @include text-button-centered-reversed;
}
.text-button-centered {
  @include text-button-centered;
}
.text-filter {
  @include text-filter;
}
.text-filter-section {
  @include text-filter-section;
}
.text-filter-active {
  @include text-filter-active;
}
.text-footer-link {
  @include text-footer-link;
}
.text-footer-section {
  @include text-footer-section;
}
.text-input-label {
  @include text-input-label;
}
.text-list-menu {
  @include text-list-menu;
}
.text-list {
  @include text-list;
}
.text-links {
  @include text-links;
}
.text-links-sm {
  @include text-links-sm;
}
.text-main-nav {
  @include text-main-nav;
}
.text-nav {
  @include text-nav;
}
.text-nav-icon-label {
  @include text-nav-icon-label;
}
.text-txt-link {
  @include text-txt-link;
}
.text-product-label {
  @include text-product-label;
}
.text-product-label-S {
  @include text-product-label-S;
}
.text-google-translate {
  @include text-google-translate;
}
body {
  //@include text-txt-M;
  @include text-txt-R;
}
.tg-button__icon {
  display: inline-block;
  margin-right: 6px;
}
.columns {
  display: flex;
  margin: 0 auto;
  margin: 48px auto 64px;
  max-width: 100%;

  .container--coupling {
    max-width: none;
  }

  & > .coupling--left {
    flex-basis: 64.166%;

    .container {
      > :first-child {
        margin-top: 0;
      }
      //:last-child {
      //  margin-bottom: 0;
      //}
    }
  }
  & > .coupling--right {
    flex-basis: 30.41%;
    margin-left: 8%;
    margin-left: auto;

    > div:not(.container) {
      margin: 48px auto;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    > .container {
      > :first-child {
        margin-top: 0;
      }
      > :last-child {
        margin-bottom: 0;
      }
      > div {
        margin: 48px auto;
      }
      //:last-child {
      //  margin-bottom: 0;
      //}
    }
  }
}
.coupling {
  max-width: 100%;
  //overflow:  hidden;

  @include media-breakpoint-up(md) {
    overflow: visible;
  }
}
.coupling--full {
  .container--coupling {
    width: 100%;
    max-width: none;
  }
}
.coupling--full-max {
  .container--coupling {
    max-width: 1700px;
  }
}
.coupling--full-centered,
.coupling--centered {
  .container--coupling {
    max-width: none;
  }

  @include media-breakpoint-up(xl) {
    .container--coupling {
      min-width: 1200px;
      width: 81.25%;
      max-width: 1380px; //81.25% of 1700
    }
  }
}
.coupling--centered-thin {
  .container--coupling {
    max-width: 800px;
  }
  //
  //@include media-breakpoint-up(xl) {
  //  .container--coupling {
  //    width: 53%;
  //  }
  //}
}
.container {
  @include media-breakpoint-up(xl) {
    max-width: 1200px;
  }
}
.form-error {
  color: $c-error;
}
.d-16-9 {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  & > div, iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.d-3-2 {
  width: 100%;
  height: 0;
  padding-bottom: 66.66%;
  position: relative;

  & > div, iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
hr {
  margin-left: -15px;
  margin-right: -15px;

  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: 0;
  }
}
.hr--separator {
  margin: 20px 0 24px;
}
.hr--separator-project-details {
  margin: -13px 0 36px;
}
.hr--white-light {
  border-top: 1px solid rgba(255, 255, 255, .30);
}
.icon {
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
}
@mixin input-base() {
  border: 1px solid $c-blue-input;
  border-radius: 4px;
  padding: 7px 11px;
  background-color: white;

  &.input--error,
  &.is-invalid {
    border: 2px solid $c-error;
    padding: 6px 10px;
    background-color: $c-error-bg-solid;
  }
  &:focus,
  &.input--focused {
    border: 2px solid $c-active;
    padding: 6px 10px;
    background-color: $c-focused-bg-solid; //$c-focused-bg;
    box-shadow: 0 0 0 2px rgba(255, 133, 99, .3);
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: .34; /* Firefox */
  }
}
@mixin input-checkbox() {
  width: 18px;
  height: 18px;
  cursor: pointer;
  visibility: hidden;
  padding: 0;
  margin: 0 8px 0 0;
  border: 0;
  margin-bottom: 11px;
  vertical-align: middle;

  & + label {
    @include text-txt-S;
    margin-bottom: 11px;
    vertical-align: middle;
  }

  &:after {
    content: " ";
    width: 18px;
    height: 18px;
    background-color: #fff;
    display: inline-block;
    visibility: visible;
    border: 1px solid $c-blue-input-light;
  }
  &:checked:after {
    border: 2px solid #FF8563;
    background-image: url(../img/icons/icon-checkmark-orange.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}
.input {
  @include input-base();
}
.is-loading {
  display: none;
}
p {
  margin-bottom: 1em;
}
.main-content {
  @include media-breakpoint-up(md) {
    //margin-top: 72px;
  }
}
.no-results {
  @include text-txt-L;
  margin: 50px 0;
}
a, .superlink {
  @include text-txt-R-link;
  cursor: pointer;
  //  &:hover, &:visited, &:active {
  //    color: initial;
  //  }
}
input, textarea {
  caret-color: #FF8563;
}
textarea {
  border: 1px solid $c-blue-input-light;
  border-radius: 2px;
}
.gutter--top {
  padding-top: 40px;
}
@mixin navigationlink--blue-outline() {
  margin: 16px 0;

  .navigationlink__link {
    .navigationlink__title {
      border: 1px solid rgba(132, 187, 198, 0.78);
      border-radius: 100px;
      padding: 2px 10px 2px 30px;
      display: inline-block;
      background-size: 12px 9px;
      background-repeat: no-repeat;
      background-position: left 10px center;
      background-image: url(../img/icon-arrow-right-black.png);
    }

    &:hover {
      .navigationlink__title {
        border-color: black;
        color: black;
      }
    }
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes opacityFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}
@keyframes translateSlideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
.test-controls {
  border: dotted black 2px;
  margin: 10px auto;
  padding: 10px;
  width: 800px;
  font-size: 10px;
}
.map-loading-icon {
  display: none;
}
.map-loading-icon-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
//
//tablet-max
@include media-breakpoint-down(md) {
  .columns {
    flex-wrap: wrap;
    width: 100%;

    & > .coupling--left {
      flex-basis: 100%;
    }
    & > .coupling--right {
      width: 100%;
      flex-basis: 100%;
      margin: 0;
      margin-top: 25px;
    }
  }
}
//
// desktop+
@include media-breakpoint-up(md) {
  .columns {
    max-width: 1200px;
  }
  .hr--separator-project-details {
    margin: -5px 0 32px;
  }
  .map-loading-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .map-loading-icon-mobile {
    display: none;
  }
}
//
//large desktop+
@media screen and (min-width: 1440px) {
  .columns {
    max-width: 1380px;
    width: 83.4%;
    width: 81.25%;
    //width:     83.4%;
    min-width: 1200px;
  }
}

select {
  border: 1px solid rgba(132, 187, 198, 0.78) !important;
  background-image: url(../img/icon-chevron-black-down.png);
  background-position: right 16px center;
  background-size: 15px auto;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  // option {
  //   background-color: white;
  //   padding-top: 8px;
  //   padding-bottom: 8px;
  // }
}

select::-ms-expand {
  display: none;
}

.button-input__input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.34);

  &.search-input-open {
    border-bottom: 1px solid black;
  }
}

.no-search-results {
  padding-bottom: 20px;
}

.no-results-suggestion {
  padding-top: 20px;
}

@media screen and (max-width: 991px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select,
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
