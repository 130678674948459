.m-projectscurrent {
  margin: 35px auto 64px;
}
.m-projectscurrent__buttons {
  align-items: center;
  padding: 5px 0;
}
.m-projectscurrent__buttons-container {
  padding-left: 10px;
  padding-right: 10px;
}
.m-projectscurrent__description {
  @include text-txt-S;
}
.m-projectscurrent__explore {
  margin: 4px 0;
}
.m-projectscurrent__intro-container {
  padding: 0 0 20px;
}
.m-projectscurrent__load-more {
  //margin: 0 0 64px;
}
.m-projectscurrent__title {
  @include text-h1;
}
@include media-breakpoint-up(md) {
  .m-projectscurrent {
    margin: 55px auto;
  }
  .m-projectscurrent__buttons {
    padding: 24px 0;
  }
  .m-projectscurrent__intro-container {
    padding: 0;
  }
  .m-projectscurrent__load-more {
    margin: 0;
  }
}
