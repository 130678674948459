.m-projectlistmap {
  width: 100%;
  margin-bottom: 64px;
}
.m-projectlistmap.m-projectlistmap--external{
  height: 100%;
  margin: 0;

  .m-projectlistmap__map-iframe-container {
    height: 100%;
  }
  iframe.m-projectlistmap__map-iframe {
    height: 100%;
  }
}
.m-projectlistmap__map-iframe {
  border: 0;
  width: 100%;
  height: 400px;
}
.m-projectlistmap__map-iframe-container {
  position: relative;
}
.m-projectlistmap__results-link {
  @include text-txt-R-link;
  display: block;
  margin: 0 0 24px 16px;
}
.m-projectlistmap__listing {
  margin: 8px 0px 64px;
}
.m-projectlistmap__load-more {
  margin-top: 10px;
}
.m-projectlistmap__map {
  visibility: hidden;
  position: absolute;
}
.m-projectlistmap__map--show-map {
  visibility: visible;
  position: static;
}
.m-projectlistmap__search {
  margin-bottom: 0;
}
.m-projectlistmap__toolbar {
  margin-top: 23px;
  border: 0px solid transparent;
  border-top-width: 1px;
  border-bottom-width: 1px;
  padding: 8px 0;
}
.m-projectlistmap__toolbar-buttons {
  display: none;
}
.m-projectlistmap__toolbar-clear-all {
  display: none;
}
.m-projectlistmap__toolbar-close {
  display: none;
}
.m-projectlistmap__toolbar-col-buttons {
  display: none;
}
.m-projectlistmap__toolbar-col-filter {
  flex: 1 0 100px;
  padding-right: 0;
}
.m-projectlistmap__toolbar-col-filter-title {
  display: none;
}
.m-projectlistmap__toolbar-col-programs {
  display: none;
}
.m-projectlistmap__toolbar-col-statuses {
  display: none;
}
.m-projectlistmap__toolbar-col-toggle {
  flex: 0 0 250px;
}
.m-projectlistmap__toolbar-col-types {
  display: none;
}
.m-projectlistmap__toolbar-filter {
  @include text-txt-R-link;
}
.m-projectlistmap__toolbar-select {
  width: 100%;
}
.m-projectlistmap__toolbar-select-title {
  @include text-input-label;
  margin-bottom: 5px;
}
.m-projectlistmap__toolbar--map-view {
  background-color: #EDF0F2;
  border-color: $c-blue-input-light;
}
.m-projectlistmap__toolbar--fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
  margin: 0;
  padding: 0;

  .select {
    .select__cover {
      display: none;
    }
    .flyout {
      display: none;
    }
  }
  .m-projectlistmap__toolbar-buttons {
    flex-direction: row;
    width: 100%;
    display: flex;
    flex: 1;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid $c-blue-input-light;

    .tg-button {
      margin: 0 3px;
    }
  }
  .m-projectlistmap__toolbar-container {
    > .row {
      flex-direction: column;
    }
  }
  .m-projectlistmap__toolbar-clear-all {
    @include text-txt-R-link;
    background: none;
    border: none;
    display: inline-block;
    margin: 0 30px;
  }
  .m-projectlistmap__toolbar-close {
    display: block;
    //position:            absolute;
    //top:                 0;
    //right:               0;
    width: 64px;
    height: 64px;
    background-color: transparent;
    background-image: url(../img/icons/icon-close.svg);
    background-size: 24px auto;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 0;
    color: transparent;
    border: 0;
    margin-left: auto;
    padding: 0;
  }
  .m-projectlistmap__toolbar-col-filter {
    display: none;
  }
  .m-projectlistmap__toolbar-col-filter-title {
    display: block;
    @include text-h5;
    text-transform: uppercase;
    margin: 0 0 30px;
  }
  .m-projectlistmap__toolbar-col-programs {
    display: block;
    margin: 0 0 20px;
  }
  .m-projectlistmap__toolbar-col-statuses {
    display: block;
  }
  .m-projectlistmap__toolbar-col-toggle {
    display: none;
  }
  .m-projectlistmap__toolbar-col-types {
    display: block;
    margin: 0 0 30px;
  }
}
.m-projectlistmap__toolbar-container {
  form {
    display: flex;
    flex-grow: 1;

    .form-group {
      margin-bottom: 0;
      label {
        @include text-input-label;
        margin-bottom: 5px;
      }
    }
  }
}
.m-projectlistmap__view-toggle {
  display: inline-block;
  border: 1px solid $c-blue-input;
  border-radius: 100px;
  padding: 3px;
  background-color: white;
}
.m-projectlistmap__view-toggle-button {
  @include text-button;
  padding: 6px 11px;
}
//
//tablet+
@include media-breakpoint-up(md) {
  //.m-projectlistmap {
  //  margin-top: 90px;
  //}
  .m-projectlistmap__listing {
    margin: -25px 0 55px;
  }
  .m-projectlistmap__load-more {
    margin-top: 0;
  }
  .m-projectlistmap__search {
    margin-bottom: 48px;

    //.button-input__input {
    //  @include text-h2;
    //
    //  .button-input__button--clear{
    //
    //  }
    //  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    //    @include text-input-text;
    //    font-weight: 300;
    //    opacity: .34; /* Firefox */
    //  }
    //}
  }
  .m-projectlistmap__toolbar {
    margin: 48px auto 0px;
    padding: 20px 0;
  }
}
//
//small desktop+
@include media-breakpoint-up(lg) {
  .m-projectlistmap__map-iframe {
    height: 800px;
  }
  .m-projectlistmap__toolbar-col-filter {
    display: none;
  }
  .m-projectlistmap__toolbar-col-programs {
    flex: 1 0 200px;
    display: block;
  }
  .m-projectlistmap__toolbar-col-statuses {
    flex: 1 0 200px;
    display: block;
  }
  .m-projectlistmap__toolbar-col-toggle {
    margin-left: auto;
  }
  .m-projectlistmap__toolbar-col-types {
    flex: 0 0 375px;
    display: block;
    //text-align: center;
  }
}
//
//
//
//misc todo:put in common.js or name with BEM
.map-filter {
  background-color: #E8E9EB;
  padding: 1px;
}
.test-controls {
  border: dotted black 2px;
  margin: 10px auto;
  padding: 10px;
  width: 800px;
  font-size: 10px;
}
