.m-intro {
  //&.coupling--centered {
  //  display:   block;
  //  max-width: 770px;
  //  margin:    0 auto;
  //
  //  .container {
  //    min-width: 0;
  //    width:     100%;
  //    max-width: none;
  //  }
  //}
}
.m-intro__title {
  @include text-h1;
  margin-bottom: 16px;
}
.m-intro__description {
  @include text-txt-L;
  max-width: 740px;
  margin:    16px auto 20px 0;
}
@include media-breakpoint-up(xl) {
  .m-intro__title {
    margin-bottom: 20px;
  }
  .m-intro__description {
    margin: 24px auto 50px 0;
  }
}
