.m-listshare {
  font-size:   12px;
  font-weight: 500;
  text-align:  center;
}
.m-listshare__link{
  cursor: pointer;
  color:  rgba(0, 0, 0, 0.78);
}
.m-listshare__link-icon {
  margin-left: 16px;
  font-size:   16px;
}
.page {
  .m-listshare {
    padding-top: 16px;
    margin:      48px 14px 0px;
    border-top:  1px solid rgba(0, 0, 0, .15);
  }
}
