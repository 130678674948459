.project-stub {
  &:hover {
    .project-stub__thumbnail-img {
      transform: scale(1.05);
    }
  }
}
.project-stub__link {
  display: inline-block;
}
.project-stub__thumbnail {
  margin-bottom: 20px;
  display:       block;
}
.project-stub__thumbnail-img {
  width:      100%;
  height:     auto;
  transition: #{$transition-fast} all;
}
.project-stub__program-label {
  margin-bottom: 12px;
}
.project-stub__title {
  @include text-h3;
  margin-bottom: 6px;
  display:       block;

  &:hover {
    color: black;
  }
}
.project-stub__description {
  @include text-txt-S;
}
