.programs-detail__content {
  .m-intro__title {
    font-size: 48px;
    color: black;
    line-height: 1.25;
    margin-bottom: 48px;
  }

  .m-intro__description {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 48px;
  }
}

//.programs-detail__sidebar {
//  .navigationlink--blue-outline {
//    .navigationlink__link {
//      .navigationlink__title {
//        font-size: 12px;
//      }
//    }
//  }
//}
