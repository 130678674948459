.m-header {
  background-color: transparent;
  //position: static;
  position: absolute;
  top: 3em;
  z-index: 3;
  width: 100%;

  .navigationlink__description {
    margin: 5px 0 0;
  }
  .container--coupling {
    position: relative;
  }
  .project-type-list__project-type {
    opacity: 1;
  }
  .project-type-list__project-type--is-active {
    .project-type-list__project-type-icon {
      filter: none;
      border-color: transparent;
    }
  }
}
.m-header__expanded-tab-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 0;
  width: 0;
  border-top: 4px solid black;
  opacity: 0;
  transition: #{$transition-slow} left, #{$transition-medium} opacity;
  transform: translateX(-50%);

  &.m-header__expanded-tab-bar--is-visible {
    opacity: 1;
  }
}
.m-header__logo {
  margin-left: 16px;
}
.m-header__logo-img {
  max-height: 40px;
  max-width: 155px;
}
.m-header__menu-toggle {
  font-size: 0;
  color: transparent;
  background-image: url(../img/icons/icon-menu.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  position: absolute;
  left: 0;
  top: 35px;
  transform: translateY(-50%);
  padding: 25px 15px;

  border: 0;
  background-color: transparent;
}
.m-header__message {
  display: none;
}
.m-header__message-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  bottom: 0;
}
.m-header__message-description {
  @include text-txt-XS-lighter;
  word-wrap: break-word;
}
.m-header__message-title {
  @include text-list;
}
.m-header__project-types {
  margin: 15px 0 15px;
}
.m-header__search-content {
  margin: 0 auto;
  max-width: 770px;
  margin-bottom: 264px;
  padding-top: 1px;

  .button-input__button--search {
    display: none;
  }
}
.m-header__search-input {
  @include text-h2;
  background-color: transparent;
  padding: 5px 0;
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
}
.m-header__search-title {
  @include text-input-label;
  margin-top: 65px;
}
.m-header__subhead {
  display: flex;
  margin: 15px 0 0;
}
.m-header__subhead-back {
  background-image: url(../img/icons/icon-arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  font-size: 0;
  color: transparent;
  position: absolute;
  top: 15px;
  left: 0;
  width: 16.5%;
  height: 60px;
  border: 0;
  background-color: transparent;
  visibility: hidden;
}
.m-header__subhead-title {
  @include text-main-nav;
  text-transform: uppercase;
  padding: 20px 15px;
  border-bottom: 1px solid $c-separator;
  flex: 1 1;

  &:hover {
    text-decoration: none;
  }
}
.m-header__search-match {
  font-weight: bold;
}
.m-header__search-preview {

}
.m-header__search-results {
  display: none;
}
.m-header__tab {
  &.is-searching {
    .m-header__search-preview {
      display: none;
    }
    .m-header__search-results {
      display: block;
    }
  }
  &[aria-expanded="true"] {
    .m-header__tab-menu-content {
      button, a {
        visibility: visible;
      }
    }
  }
}
.m-header__tab--search {
  display: block;

  .m-header__tab-link {
    font-size: 0;
    color: transparent;
    background-image: url(../img/icons/icon-search.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 50px;
    position: absolute;
    right: 0;
    top: 35px;
    transform: translateY(-50%);
    padding: 25px 15px;
    border: none;
  }
  .m-listnav {
    margin: 20px 0 0;
  }
  .button-input__input {
    @include text-h2;
    padding-left: 0;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    background-color: transparent;
  }
  .navigationlink__title {
    padding: 0 15px 10px;
  }
}
.m-header__tab-bar {
  height: 100%;

  > .container--coupling {
    height: 100%;

    > .row {
      height: 100%;
      align-items: flex-start;
    }
  }
}
.m-header__tab-link {
  @include text-txt-R-bold;
  display: none;
  background-image: url(../img/icons/icon-caret-right.svg);
  background-position: center right 35px;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  border: 0;
  background: transparent;
  text-align: left;
  height:100%;

  &:hover {
    color: #000;
  }
}
.m-header__tab-link--mobile {
  @include text-txt-R-bold;
  padding: 20px 15px;
  display: block;
  text-transform: uppercase;
  border-bottom: 1px solid $c-separator;

  //& + .m-header__tab-link--mobile:not(.is-active) {
  //  border-bottom: 1px solid $c-separator;
  //}

  width: 100%;
  background: transparent;
}
.m-header__tab-menu {
  min-height: 445px;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #E8E9EB;
  top: 0;
  background-size: 0;
  padding-left: 16.5%;
  left: 100%;
  transition: #{$transition-medium} all;
  z-index: 2;
}
.m-header__tab-menu-container {
  display: block;
}
.m-header__tab-menu-content {
  height: 100%;
  background-color: #E8E9EB;

  button, a {
    //visibility: hidden;
  }
}
.m-header__tab-toggle {
  display: none;
}
.m-header__tabs {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding: 20px 0 13px;
  height: 4.6em;
  border-bottom: 1px solid $c-separator;
  transition: #{$transition-medium} background-color;
  transition-delay: #{$transition-medium};
}
.m-header__tabs-middle {
  width: 100%;
  position: relative;
  flex: 1 1;
  //border-top:   1px solid $c-separator;
  margin-top: 13px;
  padding-top: 64px;
  padding-left: 16.5%;
}
.m-header__vision-statement {
  @include text-h2;
  word-wrap: break-word;
}
.m-header__who-we-are-container {
  flex: 1 1;
}
.m-header__who-we-are-row-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
//
//mobile only
@include media-breakpoint-down(md) {
  .m-header {
    .navigationlink--button {
      margin: 0 -15px;

      &.is-active {
        .navigationlink__link {
          text-decoration: none;
        }
        .navigationlink__title {
          background-color: #DD9405;
          border-bottom: none;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          color: white;
        }
      }
    }
    .navigationlink {
      //margin: 0;
    }
  }
  .m-header__m-listnav {
    margin: 0 -15px;

    .m-listnav__navigationlink {
    }
    .navigationlink__link {
      display: block;
      //margin-left:   16.5%;
      padding: 20px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      &:hover {
        text-decoration: none;
      }
    }
    .navigationlink__title {
      @include text-txt-R-bold;
    }
  }
  .m-header__project-types {
    margin: 15px 0 25px;
  }
  .m-header__tabs {
    //height: auto;
  }
  .m-header__tabs-middle {
    position: absolute;
    left: -100%;
    background: #E8E9EB;
    height: 100%;
    top: 61px;
    //transition: #{$transition-fast};
    transition: #{$transition-slow};
    //visibility: hidden;
    overflow: scroll;
  }
  .m-header__tab {
    background-image: url(../img/icons/icon-caret-right.svg);
    background-repeat: no-repeat;
    background-position: center right 32px;

    &.is-active {
      .m-header__tab-link {
        background-color: $c-active;
        border-bottom: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &.is-open {
      background-color: #FF8563;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      .m-header__tab-menu {
        left: 0;
        visibility: visible;
      }
    }

    &:last-child {
      .m-header__tab-link {
        border-bottom: none;
      }
    }
  }
  .m-header__tab-menu {
    height: 100%;
    visibility: hidden;
  }
  .m-header__tab-menu-content {
    background-image: none !important;

    .navigationlink--button {
      .navigationlink__title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        background-color: transparent;
      }
    }
    .m-listnav__title {
      margin-top: 20px;
    }
    > .container--coupling {
      padding-bottom: 70px;
    }
  }
  .m-header__tab-menu-container {
    .row {
      flex-direction: column;
    }
  }
  .m-header__tab--search {
    .m-header__tab-menu-content {
      background-color: white;
    }
    .m-header__subhead-back {
      display: none;
    }
    .m-header__tab-menu {
      position: fixed;
      padding: 0;
      transition: none;
    }
    .m-header__subhead {
      display: none;
    }
    .m-header__search-content {
      padding: 20px 15px;
    }
    .m-header__search-title {
      margin: 0;
      //padding: 5px 0;
    }
    .navigationlink__description {
      display: none;
    }
    .m-listnav__title {
      margin-top: 0px;
    }
    .m-header__view-all-results {
      margin-top: 10px;
    }
    .m-header__search-preview {
      margin-top: 30px;
    }
    .m-header__search-results {
      margin-top: 10px;
    }
  }
  .m-header__vision-statement {
    display: none;
  }
  .page--hamburger-is-open {
    .m-header {
      z-index: 11;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 47px;
    }
    .m-header__tabs {
      background: #E8E9EB;
    }
    .m-header__tabs-middle {
      left: 0%;
      visibility: visible;
    }
  }
}
//
//laptop+
@include media-breakpoint-up(lg) {
  .m-header {
    width: 100%;
    background: transparent;
    position: absolute;

    &:hover {
      .m-header__tabs {
        background-color: #E8E9EB;
        transition: #{$transition-fast} background-color;
        transition-delay: #{$transition-fast};
      }
    }
  }
  .m-header__m-listnav {
    .navigationlink__title {
      &:hover {
        text-decoration: none
      }
    }
  }
  .m-header__menu-toggle {
    display: none;
  }
  .m-header__message {
    display: block;
    background-color: white;
    padding: 24px 24px 10px;
    width: 420px;
    margin-left: -24px;
  }
  .m-header__search-content {
    min-height: 922px;
    margin-bottom: 20px;

    .button-input__button-icon {
      max-width: 26px;
      max-height: 26px;
      width: 26px;
      height: 26px;
    }
  }
  .m-header__search-input {
    @include text-display;
  }
  .m-header__subhead {
    display: none;
  }
  .m-header__tab {
    &[aria-expanded="true"] {
      .m-header__tab-menu {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        display: block;
        opacity: 1;
        max-height: 1500px;
        //transition: #{$transition-fast} max-height;
        //transition-delay: 0s, 0s;
        padding-bottom: 70px;

        .transition {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .m-header__tab-menu-content {
        max-height: 1500px;
        min-height: 430px;

        a {
          //display: inline-block
          visibility: visible;
        }
      }
      .m-header__tab-link {
        text-decoration: none;
        color: $c-black-transparent;

        &:hover {
          color: black;
        }
      }
    }
  }
  .m-header__tab-bar {
    height: auto;
    padding: 0;

    > .container--coupling {
      height: auto;

      > .row {
        height: auto;
      }
    }
  }
  .m-header__tab-link {
    @include text-main-nav;
    padding: 28.5px 0 24.5px;
    border-bottom: 4px solid transparent;
    margin: 0 18px;
    text-transform: uppercase;
    display: inline-block;
    box-shadow: inset 0px -4px 0px 0px rgba(0, 0, 0, 0);
    transition: #{$transition-fast} all;
    background-image: none;
  }
  .m-header__tab-link--mobile {
    display: none;
  }
  .m-header__tab-listed {
    padding: 0;
    margin: 0;
    width: auto;
    border: none;
    display: inline-block;
    text-align: left;
  }
  .m-header__tab-menu {
    display: none;
    display: block;
    background-size: cover;
    position: absolute;
    top: 100%;
    padding: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    box-sizing: content-box;
    height: auto;
    margin-top: -1px;
    height: auto;
    max-height: 0;
    min-height: 0;
    padding: 0;
    opacity: 0;
    //transition: #{$transition-slow} opacity, #{$transition-slow} max-height;
    //transition-delay: 0s, 0s;
    transition: none;

    .transition {
      opacity: 0;
      transition: #{$transition-slow} opacity, #{$transition-slow} transform;
      transform: translateY(20px);
    }
    .transition-1 {
      transition-delay: .5s, .5s;
    }
    .transition-2 {
      transition-delay: .7s, .5s;
    }
    .transition-3 {
      transition-delay: .9s, .5s;
    }
  }
  .m-header__tab-menu-container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .m-header__tab-menu-content {
    box-shadow: 0 24px 40px 0 rgba(0, 0, 0, .4);
    //height:              auto;
    background-size: cover;
    background-position: center top;
    min-height: 445px;
    border-bottom: 15px solid white;
    min-height: 0;
    max-height: 0;
    overflow: hidden;
    transition: none;
    //transition: #{$transition-slow} all;

    a {
      visibility: hidden;
      //display:          none;
      //transition:       0 display;
      //transition-delay: 1s display;
    }
  }
  .m-header__tab-toggle {
    display: inline-block;
    color: transparent;
    background-color: transparent;
    border: none;
    opacity: 1;
    font-size: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    line-height: 0;
    margin-left: -15px;
  }
  .m-header__tab--search {
    margin-left: 9.375%;

    .m-header__tab-link {
      top: 50%;
      cursor: pointer;
      border-bottom: 4px solid transparent;
      padding: 0;
      width: 100px;
      background-position: right 17px center;

      &:hover {
        //border-color: black;
      }
    }
    .button-input__input {
      @include text-display;
    }
    .m-header__search-preview {
      .navigationlink__title {
        padding: 0;
      }
    }
  }
  .m-header__tabs {
    flex-direction: row;
    justify-content: center;
    //padding:         20px 16px 13px;
    padding: 0;

    //&:hover {
    //  box-shadow: 0 -1px inset $c-separator;
    //}
  }
  .m-header__tabs-middle {
    margin: 0;
    padding: 0;
    border: none;
    position: static;
    display: block;
    text-align: center;
    background-color: transparent;
  }
}
@include media-breakpoint-up(xl) {
  .m-header__logo {
    //margin-left: 9.375%;
    margin-left: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-585px);
  }
  .m-header__tabs-middle {
    margin-left: 9.375%;
  }
}
//
//large desktop+
@media screen and (min-width: 1478px) {
  .m-header__logo {
    position: static;
    transform: none;
    padding-left: 16px;
    //margin-left: 9.375%;
    position: absolute;
    left: 9.375%;
  }
  .m-header__tabs-middle {
    //margin-left: 0;
  }
}
