.m-listcards {
  position: relative;
  margin:   16px 0;
}
.m-listcards.m-listcards--bg-block {
  padding: 16px 0 0;
  margin:  16px 0 0;

  .m-listcards__list-container {
    margin-bottom: 3px;
  }
}
.m-listcards.m-listcards--take-an-action {
  //margin-bottom: 0;

  .m-listcards__bg-block {
    max-height: none;
  }
}
.m-listcards__bg-block {
  max-height:     700px;
  height:         100%;
  position:       absolute;
  top:            0;
  width:          100%;
  z-index:        -1;
  padding-bottom: 3px;
  box-sizing:     content-box;
}
.m-listcards__title {
  @include text-h3;
  margin-bottom: 32px;
}
.m-listcards__list-card {
  margin: 0 0 15px;
}
.m-listcards__list {
  width:     100%;
  display:   flex;
  flex-wrap: wrap;
}
//
//
@include media-breakpoint-up(md) {
  .m-listcards {
    margin: 48px 0;
  }
  .m-listcards.m-listcards--take-an-action {
    //margin-bottom: 0;
  }
  .m-listcards.m-listcards--bg-block {
    padding: 48px 0 0;
    margin:  48px 0 0;

    .m-listcards__list-container {
      margin-bottom: 33px;
    }
  }
  .m-listcards__bg-block {
    max-height:     600px;
    padding-bottom: 33px;
  }
}
