.m-events__filter-button {
  display: none;
  font-size: 16px;
  font-weight: 500;
  color: rgba(80, 133, 166, 1) !important;

  @include media-breakpoint-down(xs) {
    display: block;
    float: right;
  }
}

.m-events__results {
  font-size: 14px;

  @include media-breakpoint-down(xs) {
    padding-top: 24px;
  }
}

.m-events {
  .button-input {
    max-width: 570px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);

    &.search-input-open {
      border-bottom: 1px solid black;
    }

    .input {
      border: 0;

      &:focus {
        outline: none;
      }
    }
  }
}
