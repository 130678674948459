.m-latest-news__listing-item-col {
  &:last-child {
    .m-latest-news__news-item {
      border-bottom: 0;
    }
  }
}
.m-latest-news__news-item.news-item {
  padding-bottom: 15px;
  margin-top:     15px;
  border-bottom:  1px solid rgba(0, 0, 0, .15);

  .news-item__calendar {
    @include text-txt-XS-lighter;
    color: rgba(0, 0, 0, .58) !important;
  }
  .news-item__title {
    @include text-txt-S;
    padding-bottom: 0;
  }
}
.m-latest-news__title {
  font-family:    'Roboto';
  font-size:      12px;
  font-weight:    bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}
.m-latest-news--green {
  background-color: #E6F0EF;
}
.m-latest-news--horizontal {
  padding: 32px 0;

  .m-latest-news__title {
    @include text-h3;
    text-transform: none;
    margin-top:     0;
  }
  .m-latest-news__listing {
    .news-item {
      border-color: $c-separator;
    }
    .news-item__calendar {
      @include text-txt-XS-lighter;
      color: rgba(0, 0, 0, .58) !important;
    }
    .news-item__content {
      @include text-txt-S;
    }
    .news-item__description {
      display: none;
    }
    .news-item__title {
      @include text-h4-link;
      padding-bottom: 0;
    }
  }
}
//
//desktop+
@include media-breakpoint-up(lg) {
  .m-latest-news--horizontal {
    padding: 48px 0;

    .m-latest-news__listing {
      flex-direction: row;

      .news-item {
        border: none;
      }
      .news-item__description {
        display: block;
      }
      .news-item__title {
        margin-bottom: 12px;
      }
    }
    .m-latest-news__listing-item-col {
      flex:      0 0 33%;
      max-width: 33%;
    }
    .m-latest-news__title {
      margin-bottom: 18px;
    }
  }
  .m-latest-news__view-all {
    margin: 32px auto 0;
  }
}
.m-latest-events {
  .news-item {
    padding-bottom: 8px;
    margin-top:     20px;

    .news-item__calendar {
      height: 40px;
    }
    .news-item__location {
      font-size: 16px;
    }
  }
}
