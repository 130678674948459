.search-item {
  padding-bottom: 20px;
  margin-top: 16px;
  border-bottom: 1px solid #878787;

  &.remove--border {
    border-bottom: 0;
  }

  .search-item__tag {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: #5085a6;
    padding: 4px 8px;
    margin-bottom: 6px;
    text-transform: capitalize;
    background-color: rgba(206, 234, 242, 0.78);
    border-radius: 100px;
    display: inline-block;
  }

  .search-item__content {
    flex-grow: 1;

    .search-item__link {
      color: rgba(0, 0, 0, 0.78);
    }

    .search-item__title {
      font-size: 20px;
      font-family: "Roboto";
      font-weight: 500;
      padding-bottom: 8px;
      color: rgba(0, 0, 0, 0.78);
    }
  }
}
