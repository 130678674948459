.page-getinvolvedthanks__content {
  margin: 30px 0 60px;
}
.page-getinvolvedthanks__follow {
  margin-top: 25px;
}
.page-getinvolvedthanks__title {
  @include text-h1;
  margin-bottom: 25px;
}
//
//tablet+
@include media-breakpoint-up(md) {
  .page-getinvolvedthanks__content {
    margin: 120px 0 285px;
  }
  .page-getinvolvedthanks__follow {
    margin-top: 50px;
  }
  .page-getinvolvedthanks__title {
    @include text-h1;
    margin-bottom: 65px;
  }

}
