.m-news-detail {
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.m-news-detail__tags {
  margin: 32px auto 4px;
}
.navigation-links {
  color: rgba(0, 0, 0, 0.78);
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;

  .m-listnav__navigationlink {
    .navigationlink__link {
      color: #5085a6;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
//
//tablet+
@include media-breakpoint-up(md) {
  .m-news-detail__tags {
    margin: 48px auto 20px;
  }
}
