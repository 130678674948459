.program-banner {
  display: inline-block;
  padding: 4px 8px;
}
.program-banner__title {
  @include text-base;
  font-weight:    bold;
  color:          white;
  letter-spacing: .6px;
  font-size:      10px;
  line-height:    11px;
  text-transform: uppercase;
}
