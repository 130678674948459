.m-brandbar {
  background-color: $c-blue;
  width: 100%;
  position: relative;
  //min-height:       47px;
  min-height: 3em;
  z-index: 2;
}
.m-brandbar__buttons {
  @include text-txt-XS;
  font-weight: 500;
  color: white;
  padding: 12px;
  margin-left: auto;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.m-brandbar__directory {
}
.m-brandbar__label-desktop {
  display: none;
}
.m-brandbar__language {
  margin-right: 10px;

  .google-translate .goog-te-gadget .goog-te-combo {
    border: 0px !important;
    line-height: 1;
  }
}
.m-brandbar__language-toggle {
  background-image: url(../img/icon-chevron-white-down.png);
  background-size: 12px auto;
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 4px 2px;
}
.m-brandbar__logo {
  display: inline-block;
}
.m-brandbar__services {
  margin-right: 10px;
}
//
//mobile
@include media-breakpoint-down(xs) {
  .m-brandbar__language {
    .google-translate .goog-te-gadget .goog-te-combo {
      background-color: white;
      background-image: url(../img/icons/icon-globe.svg);
      background-size: cover;
      color: transparent;
      content: " " !important;
      display: block;
      filter: invert(1);
      height: 24px;
      left: -17px;
      margin-right: 10px;
      position: absolute;
      top: 13px;
      vertical-align: middle;
      width: 24px;
      z-index: 1111;
    }
  }
}
//
//desktop+
@include media-breakpoint-up(lg) {
  .m-brandbar__buttons {
    padding: 10px;
    position: static;
    margin-top: auto;
    align-items: center;
  }
  .m-brandbar__label-desktop {
    @include text-google-translate;
    display: inline-block;
    color: white;
    vertical-align: middle;
  }
  .m-brandbar__language {
    margin-right: 20px;

    .google-translate .goog-te-gadget .goog-te-combo {
      line-height: 14px;
    }
  }
}
