.m-programbanner {
  position: relative;
  height: 70px;
  margin: -8px 0 -43px;
}
.m-programbanner__bar {
  height: 8px;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}
.m-programbanner__bg {
  left: -7px;
  width: auto;
  display: inline-block;
  padding-right: 25px;
  position: relative;
  height: 100%;
  background: white;
}
.m-programbanner__container {
  position: relative;
  height: 100%;
  margin: 0;
}
.m-programbanner__image {
  left: 8px;
  transform: translateY(-50%);
  position: relative;
  background-color: white;
  width: auto;
  height: auto;
  max-width: 140px;
  max-height: 70px;
  box-sizing: content-box;
  top: 50%;
  margin: 0;
  padding: 0;
}
.m-programbanner--project-details {
  //margin: 0;
  border-bottom: 1px solid rgba(95, 115, 122, 0.25);
  margin: -8px 0 8px;
  height: 62px;

  .m-programbanner__container {
    padding: 0;
    min-width: 0;
  }
}
//
//tablet+
@include media-breakpoint-up(md) {
  .m-programbanner--project-details {
    height: 70px;
    margin: 0 0 6px;

    .m-programbanner__bar {
      display: none;
    }
  }
}
//
//laptop+
@include media-breakpoint-up(lg) {
  .m-programbanner--project-details {
    margin: -20px 0 6px;
  }
}
//
//desktop+
@include media-breakpoint-up(xl) {
  .m-programbanner__bg {
    width: 205px;
    left: -30px;
    //margin: 20px 0 -38px;
    margin: 0;
  }
  .m-programbanner__image {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .m-programbanner__container {
    margin: auto;
  }
}
