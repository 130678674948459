.m-listtag {
}
.m-listtag__title {
  text-transform: uppercase;
  margin:         0;
  font-size:      12px;
  line-height:    15px;
  font-weight:    500;
  color:          black;
  text-transform: none;
  display:        inline-block;
  vertical-align: middle;
}
.m-listtag__list {
  width:          auto;
  display:        inline-block;
  vertical-align: middle;
}
.tag.m-listtag__tag {
  margin:         0 0 0 8px;
  vertical-align: middle;
}
