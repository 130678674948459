.button-input {
  position: relative;
}
.button-input__input {
  width: 100%;
  padding: 7px 39px;

  &:focus {
    padding: 6px 38px;
  }
}
.button-input__button {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  transition: #{$transition-slow} all ease-in-out;
  border: 0;
  flex-basis: 40px;
  padding: 0;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 40px;
  background-color: transparent;

  &:disabled {
    opacity: .5;
  }
}
.button-input__button--clear {
  left: auto;
  right: 0;
}
.button-input__button-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 18px;
  max-height: 18px;
}
