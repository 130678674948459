.m-newsletter {
}
.m-newsletter__title {
  @include text-h5;
  height: 48px;
  background-color: #d0e0ee;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.m-newsletter__content {
  background-color: #e2ecf3;
  padding: 24px;
  min-height: 218px;
}
.m-newsletter__description {
  @include text-txt-S;
  padding-bottom: 20px;
}
.m-newsletter__big {
  @include text-txt-S;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
}
.m-newsletter__email__form {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.78);
}
.m-newsletter__input {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #d0e0ee;
  padding: 2px 4px;
}
.m-newsletter__button {
  margin-top: 24px;
}
