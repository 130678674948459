.m-event-detail {
  //padding-bottom: 24px;
  margin-bottom: 24px;

  .m-contacts__title {
    text-transform: capitalize;
  }
}
.m-event-detail__tags {
  margin: 32px auto 4px;
}
.navigation-links {
  color:       rgba(0, 0, 0, 0.78);
  font-size:   20px;
  font-weight: 500;
  margin-top:  30px;

  .m-listnav__navigationlink {
    .navigationlink__link {
      color:       #5085a6;
      font-size:   16px;
      font-weight: 500;
    }
  }
}

.m-event-detail-intro {
  margin-bottom: -24px;
}
//
//tablet+
@include media-breakpoint-up(md) {
  .m-event-detail__tags {
    margin: 48px auto 20px;
  }
}
.tags-tag {
  color:            #5085a6;
  font-size:        12px;
  font-weight:      500;
  background-color: #ceeaf2;
  padding:          4px 8px;
  border-radius:    10px;
}
