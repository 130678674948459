.markdown-text {
  img {
    max-width: 100%;
  }
  iframe {
    max-width: 100%;
    margin:    25px auto;
    display:   block;
  }
}
