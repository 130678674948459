.news-item {
}
.news-item__calendar {
  font-size:      12px;
  font-weight:    400;
  line-height:    18px;
  padding-bottom: 8px;

  &.blue--item {
    color: #6ebfe6;
  }
  &.red--item {
    color: #e86a64;
  }
  &.green--item {
    color: #108a10;
  }
  &.yellow--item {
    color: #dc8511;
  }
}
.news-item__content {
  flex-grow: 1;
}
.news-item__link {
  color: rgba(0, 0, 0, 0.78);
}
.news-item__location {
  font-size:   16px;
  font-family: 'Roboto';
  color:       rgba(0, 0, 0, 0.58);
}
.news-item__title {
  @include text-h4-link;
  padding-bottom: 8px;
  color:          rgba(0, 0, 0, 0.78);
}
@include media-breakpoint-up(md) {
  .news-item__title {
    @include text-h3;
  }
}
