.map-program {
  width: 100%;
}
.map-program__intro {
  margin-bottom: 48px;
}
.map-program__intro-title {
  @include text-h1;
}
.map-program__intro-title-container {
  margin-bottom: 20px;
}
.map-program__memorial-slider {
  padding: 32px 30px 52px 30px;
}
.map-program__listing {
  margin: 8px 0px 64px;
}
.map-program__load-more {
  margin-top: 10px;
}
.map-program__map-button {
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 12px;
  z-index: 3;
  font-size: 0;
  line-height: 0;
  color: transparent;
  width: 48px;
  height: 48px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .1);
}
.map-program__map-button--filtering {
  background-image: url(../img/icons/icon-menu.svg);
  background-size: 22px auto;
  left: 8px;
}
.map-program__map-button--close {
  background-image: url(../img/icons/icon-close.svg);
  background-size: 17px auto;
  left: 64px;
}
.map-program__map-iframe {
  //width: 100%;
  //height: 100%;
  border: 0;
  position: static;
  flex: auto;
}
.map-program__map-iframe-container {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #EDF0F2;

  .map-program__feedback-tab {
    padding: 15px 15px 72px;
    background-color: white;
  }
}
.map-program__results-link {
  @include text-txt-R-link;
  display: block;
  margin: 0 0 24px 16px;
}
.map-program__search {
  margin-bottom: 0;
}
.map-program__tab-menu {
  flex: 0 0 170px;
  position: relative;
  z-index: 1;
}
.map-program__tab-menu-tabs {
  background-color: #e8e9eb;
  padding: 12px 8px;
  height: 100%;
  display: none;
}
.map-program__tab {
  cursor: pointer;
  border: 0;
  border-bottom-color: rgba(black, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 16px 0;
  transition: #{$transition-medium} padding, #{$transition-medium} margin;
  display: flex;
  color: black;
  align-items: center;
  background-image: url(../img/icons/icon-plus.svg);
  background-position: right 5px center;
  background-repeat: no-repeat;
  margin: 0 6px;

  &:hover {
    color: black;
    text-decoration: none;
  }
  &.active {
    background-color: #dd9405;
    border-radius: 5px;
    color: white;
    padding: 28px 0px;
    margin: 0 6px;
    outline: 1px solid #e8e9eb;
    background-image: url(../img/icons/icon-minus.png);
    background-position: right 15px center;
    margin: 0;

    .map-program__tab-icon {
      padding: 0 0 0 8px;
    }
  }
}
.map-program__tab-content-title {
  @include text-h2;
  margin: 5px 0 5px;
}
.map-program__tab-content-description {
  @include text-txt-S;
  margin: 5px 0 32px;
}
.map-program__tab-title {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0 36px 0 8px;
}
.map-program__memorial-posts {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 1.2px;
  margin-bottom: 16px;
}
.map-program__memorial-title {
  font-size: 28px;
  line-height: 1.25;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.78);
  padding-bottom: 8px;
}
.map-program__memorial-address {
  @include text-txt-S();
}
.map-program__memorial-content {
  margin-top: 24px;
  margin-bottom: 16px;
  //text-align: center;
}
.map-program__memorial-description {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.78);
}
.map-program__memorial-event {
  @include text-txt-S();
}
.map-program__memorial-read-more {
  margin-top: 32px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;

  .m-listnav--page-content {
    margin-top: 16px;
  }
}
.map-program__tab-menu-buttons {
  display: none;
}
.map-program__toolbar {
  margin-top: 23px;
  border: 0px solid transparent;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.map-program__toolbar-buttons {
  display: none;
}
.map-program__toolbar-clear-all {
  display: none;
}
.map-program__toolbar-close {
  display: none;
}
.map-program__toolbar-col-buttons {
  display: none;
}
.map-program__toolbar-col-filter {
  flex: 1 0 100px;
}
.map-program__toolbar-col-filter-title {
  display: none;
}
.map-program__toolbar-col-programs {
  display: none;
}
.map-program__toolbar-col-statuses {
  display: none;
}
.map-program__toolbar-col-toggle {
  flex: 0 0 250px;
}
.map-program__toolbar-col-types {
  display: none;
}
.map-program__toolbar-filter {
  @include text-txt-R-link;
}
.map-program__toolbar-select {
  width: 100%;
}
.map-program__toolbar-select-title {
  @include text-input-label;
  margin-bottom: 5px;
}
.map-program__toolbar--map-view {
  background-color: #edf0f2;
  border-color: $c-blue-input-light;
}
.map-program__toolbar--fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
  margin: 0;
  padding: 0;

  .select {
    .select__cover {
      display: none;
    }
    .flyout {
      display: none;
    }
  }
  .map-program__toolbar-buttons {
    flex-direction: row;
    width: 100%;
    display: flex;
    flex: 1;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid $c-blue-input-light;

    .tg-button {
      margin: 0 3px;
    }
  }
  .map-program__toolbar-container {
    > .row {
      flex-direction: column;
    }
  }
  .map-program__toolbar-clear-all {
    @include text-txt-R-link;
    display: inline-block;
    margin: 0 30px;
  }
  .map-program__toolbar-close {
    display: block;
    //position:            absolute;
    //top:                 0;
    //right:               0;
    width: 64px;
    height: 64px;
    background-color: transparent;
    background-image: url(../img/icons/icon-close.svg);
    background-size: 24px auto;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 0;
    color: transparent;
    border: 0;
    margin-left: auto;
    padding: 0;
  }
  .map-program__toolbar-col-filter {
    display: none;
  }
  .map-program__toolbar-col-filter-title {
    display: block;
    @include text-h5;
    text-transform: uppercase;
    margin: 0 0 30px;
  }
  .map-program__toolbar-col-programs {
    display: block;
    margin: 0 0 20px;
  }
  .map-program__toolbar-col-statuses {
    display: block;
  }
  .map-program__toolbar-col-toggle {
    display: none;
  }
  .map-program__toolbar-col-types {
    display: block;
    margin: 0 0 30px;
  }
}
.map-program__view-toggle {
  display: inline-block;
  border: 1px solid $c-blue-input;
  border-radius: 100px;
  padding: 3px;
  background-color: white;
}
.map-program__view-toggle-button {
  @include text-button;
  padding: 6px 11px;
}
.map-program__view-maps-button {
  display: block;
  width: 300px;
  max-width: 100%;
  margin: 30px auto 0;
}
.map-program__map-icon {
  filter: invert(1);
  margin: 0 10px 5px 0;
}
.map-program__tab-content-container {
  //overflow: hidden;
  //border-right: 1px solid rgba(95, 115, 122, 0.25);
  // overflow-y: auto;
  //overflow: scroll;

  iframe {
    max-width: 100%;
  }
}
.tab-content-close-icon {
  height: 0;
  top: 18px;
  right: 0;
  left: initial;
  display: flex;
  z-index: 1;
}
//
//tiny phone (iphone 5 or smaller)
@media screen and (max-width: 374px) {
  .map-program__map-button--close {
    left: 8px;
    top: 68px;
  }
}
//
// smaller than tablet
@include media-breakpoint-down(md) {
  .map-program__map {
    &.map-program__map--is-open {
      border: 0;
      display: flex;

      .map-program__tab-menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        border: 0;
        display: none;

        &.map-program__tab-menu--filters-open {
          display: block;

          .map-program__tab-menu-tabs {
            display: block;
          }
        }
      }
      .map-program__tab-content-container {
        background-color: #fdfdfd;
        padding: 12px 8px 20px;
        border-radius: 4px;
        margin-top: 4px;
      }
      .map-program__map-iframe-container {
        display: flex;
      }
    }
  }
  .map_program__tab-content {
    background: white;
    padding: 8px;
    margin-top: 4px;
    border-radius: 4px;
  }
  .map-program__tab-menu-buttons {
    flex-direction: row;
    width: 100%;
    display: flex;
    flex: 1;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid $c-blue-input-light;
    background-color: white;

    .tg-button {
      margin: 0 3px;
    }
  }
  .tab-content-close-icon {
    display: none;
  }
}
//
//tablet+
@include media-breakpoint-up(md) {
  .map-program__intro-description {
    column-count: 2;
  }
  .map-program__memorial__button-share {
    margin: 32px 0 0;
  }
  .map-program__memorial-description {
    margin: 15px 0 20px;
  }
  .map-program__memorial-know {
    margin: 14px 0 0;
    display: block;
  }
  .map-program__memorial-title {
    margin-top: 14px;
  }
}
//
//laptop+
@include media-breakpoint-up(lg) {
  .map-program__listing {
    margin: -25px 0 55px;
  }
  .map-program__load-more {
    margin-top: 0;
  }
  .map-program__map {
    display: flex;
    min-height: 800px;
    margin: 48px 0;
    border-top: 1px solid #B8D7DE;
    border-bottom: 1px solid #B8D7DE;
  }
  .map-program__map-button {
    display: none;
  }
  .map-program__map-iframe-container {
    display: block;
    position: relative;
    flex: auto;
    background-color: #EDF0F2;
    z-index: auto;
    height: auto;
  }
  .map-program__map-iframe {
    height: 100%;
    width: 100%;
  }
  .map-program__search {
    margin-bottom: 48px;
  }
  .map-program__tab {
    margin: 0 8px;
    background-image: none;

    &.active {
      background-image: none;
    }
  }
  .map-program__tab-content-container {
    display: block;
    //width: 630px;
    background-color: #fdfdfd;
    //padding: 25px 40px 25px 30px;
    //position: absolute;
    //top: 0;
    //left: 100%;
    height: 100%;
    //width: 550px;
    background: rgba(255, 255, 255, .88);
    animation: 1s ease-out 0s 1 slideInFromLeft;
    position: relative;
    top: auto;
    left: auto;
    padding: 0;
    width: 100%;
    flex: 1 1 550px;
  }
  .map-program__tab-content {
    animation: 1s opacityFadeIn, 1s translateSlideUp;
    position: absolute;
    width: 550px;
    height: 100%;
    padding: 25px 20px 25px 30px;
    right: 0;
    overflow: auto;
  }
  .map-program__tab-content-title {
    margin: 15px 0 5px;
  }
  .map-program__tab-menu {
    display: flex;
    flex-direction: row;
    width: 170px;
    flex: 0 0 170px;
    transition: #{$transition-slow} flex, #{$transition-slow} max-width;
  }
  .map-program__tab-menu--is-open {
    max-width: 720px;
    flex: 0 0 720px;
  }
  .map-program__tab-menu-tabs {
    display: block;
    border-right: 1px solid #B8D7DE;
    z-index: 1;
    position: relative;
    flex: 0 0 170px;
    width: 170px;
    padding: 12px;
  }
  .map-program__tab-title {
    padding: 0 0 0 5px;
    word-break: break-word;
  }
  .map-program__toolbar {
    margin-top: 48px;
    padding: 20px 0;
  }
  .map-program__view-maps-button {
    display: none;
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes opacityFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes translateSlideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
//
//small desktop+
@include media-breakpoint-up(lg) {
  .map-program__toolbar-col-filter {
    display: none;
  }
  .map-program__toolbar-col-programs {
    flex: 1 0 200px;
    display: block;
  }
  .map-program__toolbar-col-statuses {
    flex: 1 0 200px;
    display: block;
  }
  .map-program__toolbar-col-toggle {
    margin-left: auto;
  }
  .map-program__toolbar-col-types {
    flex: 0 0 375px;
    display: block;
    //text-align: center;
  }
}
//
//
//
//misc todo:put in common.js or name with BEM

.test-controls {
  border: dotted black 2px;
  margin: 10px auto;
  padding: 10px;
  width: 800px;
  font-size: 10px;
}

.memorial-close-button {
  padding: 0;
  cursor: pointer;

  .button__icon {
    max-width: 18px;
    max-height: 18px;
    transform: scaleX(-1);
  }
}

.memorial-map-layer-title {
  display: flex;

  .memorial-map-number-count {
    width: 30px;
    padding-right: 5px;
    text-align: right;
  }
}

