.event-item {
  display: flex;
  padding-bottom: 20px;
  margin-top: 16px;
  border-bottom: 1px solid $c-separator;
  align-items: flex-start;

  &.remove--border {
    border-bottom: 0;
  }

  .event-item__calendar {
    min-width: 72px;
    max-width: 72px;
    height: 56px;
    display: flex;
    background-color: #6ebfe6;
    border-left: 4px solid #0162ab;

    &.blue--item {
      background-color: #6ebfe6;
      border-left: 4px solid #0162ab;
    }

    &.gray--item {
      background-color: #a8a8a9;
      border-left: 4px solid #384037;
    }

    &.green--item {
      background-color: #bdf1b0;
      border-left: 4px solid #21980d;
    }

    &.yellow--item {
      background-color: #dcc469;
      border-left: 4px solid #d48405;
    }

    .event-item__month {
      align-items: center;
      display: flex;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 11px;
      font-weight: normal;
      line-height: 12px;
      text-align: center;
      text-transform: uppercase;
      width: 50%;
    }

    .event-item__day {
      color: black;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 24px;
      font-weight: normal;
      padding-top: 6px;
      width: 50%;
    }
  }

  .event-item__calendar {
    height: auto;
    position: relative;
    padding: 8px 0 10px;
    min-width: 0;
    max-width: none;
    flex: 0 0 72px;

    .event-item__month {
      width: auto;
      align-self: flex-end;
      display: block;
      flex: 1 1 48%;
      text-align: right;
    }
    .event-item__day {
      width: auto;
      letter-spacing: -2px;
      align-self: flex-end;
      flex: 1 1 52%;
      margin-left: 2px
    }
  }

  .event-item__content {
    padding-left: 24px;
    flex-grow: 1;

    .event-item__link {
      color: rgba(0, 0, 0, 0.78);
    }

    .event-item__title {
      font-size: 20px;
      font-family: 'Roboto';
      font-weight: 500;
      padding-bottom: 8px;
      color: rgba(0, 0, 0, 0.78);
    }

    .event-item__date {
      font-size: 16px;
      font-family: 'Roboto';
      color: rgba(0, 0, 0, 0.58);
    }

    .event-item__location {
      font-size: 16px;
      font-family: 'Roboto';
      color: rgba(0, 0, 0, 0.58);
    }
  }

  .event-item__description {
    @include text-txt-S;
  }
}
.events__listing {
  //.event-item {
  //  padding-bottom: 8px;
  //  margin-top:     20px;
  //
  //  .event-item__calendar {
  //    height: 40px;
  //
  //    .event-item__month {
  //      font-size:   11px;
  //      line-height: 12px;
  //    }
  //
  //    .event-item__day {
  //      font-size:   30px;
  //      align-self:  center;
  //      padding-top: 0;
  //    }
  //  }
  //
  //  .event-item__content {
  //
  //    .event-item__title {
  //      font-size:   14px;
  //      font-weight: 400;
  //      line-height: 20px;
  //    }
  //
  //    .event-item__date {
  //      font-size: 16px;
  //    }
  //
  //    .event-item__location {
  //      font-size: 16px;
  //    }
  //  }
  //}
  .events__listing-item {
    &:last-child {
      .event-item {
        border-bottom: none;
      }
    }
  }
}

.m-event-detail-intro__calendar {
  .react-add-to-calendar {
    position: relative;
    .react-add-to-calendar__wrapper {
      padding-bottom: 8px;
      .react-add-to-calendar__button {
        border-radius: 100px;
        margin: 0;
        display: inline-block;
        padding: 0;
        border-color: transparent;
        border-width: 2px;
        border-style: outset;

        span {
          @include text-button-centered-reversed;
          display: block;
          color: white;
          text-decoration: none;
          height: 100%;
          padding: 10px 16px;
          border-radius: 100px;
          background-color: rgba(0, 0, 0, .78);
        }
      }
    }

    .react-add-to-calendar__dropdown {
      position: absolute;
      background-color: #eee;
      padding: 4px 8px;
      border: 1px solid #a29595;
      ul {
        list-style-type: none;
        padding: 4px 12px;
        margin-bottom: 0;
      }
    }
  }
}
