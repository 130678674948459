.m-evnet-detail-intro {
  display: block;
  padding: 15px 15px 25px 15px;

  .view-map {
    margin-left: 30px;
    color: #5085a6 !important;
    text-transform: none;
    cursor: pointer;
  }
}

.m-event-detail__intro__title {
  @include text-h1;
}

.m-event-detail__intro__description {
  @include text-txt-L;
  font-size: 16px;
  line-height: 1.5;
  max-width: 740px;
  margin:    12px auto 12px 0;
  color: rgba(0, 0, 0, 0.58);
}