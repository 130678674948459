.select {
  position: relative;
  display: inline-block;

  .flyout {
    display: none;
  }
  select {
    @include text-input-label;
    vertical-align: middle;
    border: 0;
    padding: 11px 39px 11px 9px;
    flex-shrink: 0;
    color: black;
    border-radius: 2px;
    border: .5px solid $c-blue-input;
    display: inline-block;
    height: 40px;
    //width:               100%;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    background: white;
    background-image: url(../img/icons/icon-caret-down.svg);
    background-size: 15px auto;
    background-position: right 16px center;
    background-repeat: no-repeat;

    /* This is to remove the arrow of select element in IE */
    &::-ms-expand {
      display: none;
    }

    &.form-control:focus {
      color: black;
    }
  }
  .select__cover {
    width: 100%;
    height: 100%;
    display: block;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.select--is-open {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 12px 20px -20px rgba(0, 0, 0, 0.4);

    .flyout {
      display: block;
      background-color: white;
      position: absolute;
      top: 100%;
      z-index: 2;
      padding: 15px 0;
      max-height: 500px;
      max-height: 90vh;
      overflow-y: scroll;
      width: 100%;
      max-height: 630px;
      overflow: auto;
      border: 1px solid $c-blue-input;
      margin-top: -1px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 12px 20px -20px rgba(0, 0, 0, 0.4);

      &::-webkit-scrollbar-thumb {
        //width: 6px;
        background-color: white;
      }
    }
    select {
      background: white;
    }

    &:after {
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 13px;
      right: 16px;
      content: "\A0";
      background-image: url(../img/icons/icon-caret-down.svg);
      background-size: 15px auto;
      background-position: center;
      background-repeat: no-repeat;
      transform: rotateZ(180deg);
    }
  }
  &.select--is-unset {
    select {
      color: rgba(0, 0, 0, .3);
    }
  }
}
.select-container {
  label {
    @include text-input-label;
    display: block;
    margin-top: 20px;
  }
  .select {
    display: block;
  }
}
