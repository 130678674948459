.mediaitem {
  cursor: pointer;
}
.mediaitem__media-video {
  max-width: 100%;
}
.mediaitem__media-img {
  background-size: cover;
  background-position: center top;
  transition: #{$transition-fast} all;

  &:hover {
    transform: scale(1.05);
  }
}

.media-column-item {
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0;

  &:last-child {
    margin-bottom: 0;
  }
}


//
//tablet+
@include media-breakpoint-up(md) {
  .media-column-item {
    //max-width: 232px;
    //max-height: 155px;
    //margin-right: 14px;
    //margin-bottom: 16px;
    padding-right: 14px;
  }
}
//.media-column-item--project-detail{
//  .mediaitem {
//    padding: 0 0 0 14px;
//  }
//}
