.project-type-list {
  //display: flex;
}
.project-type-list__project-type {
  margin: 0 5px;
  text-align: center;
  opacity: .5;
  cursor: pointer;
  //flex: auto;
  display: inline-block;

  .project-type-list__project-type-icon {
    width: 56px;
    height: auto;
    border-radius: 100px;
    border: 1px solid transparent;
  }

  &:hover {
    text-decoration: none;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    &:hover {
      opacity: 1;

      .project-type-list__project-type-icon {
        filter: invert(0.4) sepia(1) saturate(5) hue-rotate(325deg);
        border-color: #FF8563;
      }
    }
  }
}
.project-type-list__project-type-title {
  @include text-icon-label;
  text-transform: uppercase;
  text-align: center;
  margin: 5px 0;
}
.project-type-list__project-type--is-active {
  opacity: 1;

  .project-type-list__project-type-icon {
    border-color: black;
  }
}
