.m-news-detail-intro {
  display: block;
  //padding: 15px;
}
.m-news-detail__intro__title {
  @include text-h1;
}
.m-news-detail__intro__description {
  @include text-txt-R-link;
  font-size: 12px;
  max-width: 740px;
  margin:    10px auto 10px 0;
  color:     rgba(0, 0, 0, 0.58);
}
