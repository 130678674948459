.tg-modal {
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: none !important;
}
.tg-modal__body {
  padding: 10px 0;
}
.tg-modal__body-text {
  @include text-txt-R;
}
.tg-modal__body-subtext {
  @include text-txt-XS-lighter;
  margin-top: 5px;
}
.tg-modal__content {
  padding: 32px;
  width: 100%;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 280px;
}
.tg-modal__footer {
  justify-content: center;
  padding: 0;
  border: 0;
}
.tg-modal__header {
  padding: 0;
  border: 0;
}
.tg-modal__header-text {
  @include text-h4;
  padding: 10px 0;
}
.tg-modal__img-alert {
  display: block;
}
.tg-modal__img-check {
  padding: 19px 23px;
  background-color: #61cb6b;
  width: 64px;
  border-radius: 50%;
  height: 64px;
}
.tg-modal--thanks {
  .tg-modal__header-text {
    padding: 30px 0 32px;
  }
}
.tg-modal--warning {
  .tg-modal__content {
    padding: 16px 32px 32px;
    background: #ffc828;
  }
  .tg-modal__header {
    border-bottom: 1px solid rgba(92, 52, 0, 0.2);
  }
}
