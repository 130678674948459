//.checkbox-item-container {
//  display: block;
//  position: relative;
//  padding-left: 35px;
//  margin-bottom: 4px;
//  cursor: pointer;
//  font-size: 14px;
//  line-height: 1.5;
//  font-family: 'Roboto';
//  color: rgba(0, 0, 0, 0.78);
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
///* Hide the browser's default checkbox */
//.checkbox-item-container input {
//  position: absolute;
//  opacity: 0;
//  cursor: pointer;
//  height: 0;
//  width: 0;
//}
///* Create a custom checkbox */
//.checkmark {
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: 20px;
//  width: 20px;
//  background-color: transparent;
//  border: 1px solid rgba(132, 187, 198, 0.78);
//}
///* On mouse-over, add a grey background color */
//.checkbox-item-container:hover input ~ .checkmark {
//  background-color: #eee;
//}
///* When the checkbox is checked, add a blue background */
//.checkbox-item-container input:checked ~ .checkmark {
//  background-color: white;
//  border: 2px solid #FF8563;
//}
///* Create the checkmark/indicator (hidden when not checked) */
//.checkmark:after {
//  content: "";
//  position: absolute;
//  display: none;
//  color: white;
//}
///* Show the checkmark when checked */
//.checkbox-item-container input:checked ~ .checkmark:after {
//  display: block;
//}
///* Style the checkmark/indicator */
//.checkbox-item-container .checkmark:after {
//  left: 6px;
//  top: 3px;
//  width: 5px;
//  height: 10px;
//  border: solid #FF8563;
//  border-width: 0 3px 3px 0;
//  -webkit-transform: rotate(45deg);
//  -ms-transform: rotate(45deg);
//  transform: rotate(45deg);
//}
.list-filter-title {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
  color: rgba(0, 0, 0, 0.78);
  padding: 16px 0;
}
.list-filters-title {
  font-size: 12px;
  font-family: 'Roboto';
  line-height: 1.25;
  letter-spacing: 1.2px;
  font-weight: bold;
  color: black;
  padding-bottom: 16px;
  text-transform: uppercase;

  .list-filter-clear-link {
    margin-left: 30px;
    color: #5085a6 !important;
    text-transform: none;
    cursor: pointer;
    font-weight: 500;
  }
}
.list-filters-date-container {
  display: flex;
  justify-content: space-between;

  .react-datepicker-wrapper {
    width: 97%;
  }

  .list-filter-date-picker {
    border: 1px solid rgba(132, 187, 198, 1);
    border-radius: 3px;
    display: block;
    width: 100%;
  }
}
.list-modal {
  position: absolute;
  padding: 0;
  background-color: white;
  overflow: auto;
  width: 100%;
  height: 100%;
}
.list-modal-overlay {
  z-index: 3;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.list-filters {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
.modal-close-button {
  float: right;
  padding: 12px;

  .button__icon {
    max-width: 18px;
    max-height: 18px;
  }
}
.list-filters-modal {
  padding: 32px 12px 12px 12px;
}
.filter-modal-footer {
  border-top: 1px solid rgba(132, 187, 198, 0.58);
  padding: 24px 12px;
  text-align: right;

  .filter-modal-footer-button {

  }
}
.lister-filter-date-wrapper {
  position: relative;

  .date-button__icon {
    bottom: 16px;
  }
  input {
    @include text-input-text;
    padding: 7px 7px 7px 14px;
  }
}
.date-button__icon {
  position: absolute;
  z-index: 2;
  right: 7%;
  max-width: 12px;
  bottom: 9px;
  pointer-events: none;
}
.checkbox-item-container {
  input[type=checkbox] {
    @include input-checkbox;
  }
}
