.page-getinvolvedapply {
}
.page-getinvolvedapply__online {
  margin: 20px 0 40px;
}
.page-getinvolvedapply__online-title {
  @include text-h3;
  margin-bottom: 20px;
}
.page-getinvolvedapply__online-description {
  margin: 20px 0;

}
.page-getinvolvedapply__online-button {
  margin-top: 20px;
}
.page-getinvolvedapply__paper {
  margin: 20px 0 0;
}
.page-getinvolvedapply__paper-address {
  @include text-txt-R-bold;
}
.page-getinvolvedapply__paper-title {
  @include text-h3;
  margin-bottom: 20px;
}
.page-getinvolvedapply__paper-description {
  margin: 20px 0;
}
.page-getinvolvedapply__paper-list {
  margin: 20px 0;
}
.page-getinvolvedapply__paper-instructions {
  margin-top: 20px;
}
.page-getinvolvedapply__process {
  margin: 48px auto;
}
.page-getinvolvedapply__process-description {
  margin: 24px 0 40px;
}
.page-getinvolvedapply__process-item {
  list-style:     none;
  margin:         0;
  padding:        0;
  display:        flex;
  flex-direction: column;
}
.page-getinvolvedapply__process-item-col-content {
}
.page-getinvolvedapply__process-item-col-number {
}
.page-getinvolvedapply__process-item-description {
}
.page-getinvolvedapply__process-item-map {
}
.page-getinvolvedapply__process-item-title {
  @include text-h4;
  margin: 15px auto 20px;
}
.page-getinvolvedapply__process-list {
  list-style: none;
  margin:     0;
  padding:    0;
}
.page-getinvolvedapply__process-number-md {
  display: none;
}
.page-getinvolvedapply__process-number-text {
  color:       white;
  font-size:   24px;
  font-weight: bold;
  line-height: 28px;
  position:    absolute;
  top:         50%;
  left:        50%;
  transform:   translate3d(-50%, -50%, 0);
}
.page-getinvolvedapply__process-number-bg {
  width:         54px;
  height:        54px;
  transform:     rotate(45deg);
  border-radius: 4px;
}
.page-getinvolvedapply__process-number {
  font-size:   20px;
  line-height: 32px;
  font-weight: 400;
  margin:      20px 0 0;
}
.page-getinvolvedapply__process-title {
  @include text-h3;
  margin: 0 0 24px;
}
//
// < tablet
@include media-breakpoint-down(sm) {
  .page-getinvolvedapply__process-item-title {
    color: $c-black-transparent !important;
  }
}
//
//tablet+
@include media-breakpoint-up(md) {
  .page-getinvolvedapply__process-number {
    display: none;
  }
  .page-getinvolvedapply__process-description {
    margin: 24px 0;
  }
  .page-getinvolvedapply__process-item-col-content {
    margin-bottom: 20px;
  }
  .page-getinvolvedapply__process-item-col-number {
    margin-right:  30px;
    margin-bottom: 30px;
  }
  .page-getinvolvedapply__process-item {
    flex-direction: row;
  }
  .page-getinvolvedapply__process-item-title {
    margin: 5px auto 10px;
  }
  .page-getinvolvedapply__process-number-md {
    display:  block;
    margin:   9px;
    position: relative;
  }
}
