.contact-item {
  margin-top: 20px;

  p {
    margin-bottom: 5px;
  }
}
.contact-item__name {
  @include text-h4;
  margin-bottom: 8px;
}
