.tg-button {
  cursor: pointer;
  display: inline-block;
  transition: #{$transition-slow} all ease-in-out;
  line-height: 0;
  padding: 10px 16.5px;
  border: 0;

  .tg-button__label {
    @include text-button;
    transition: #{$transition-slow} all ease-in-out;
    text-decoration: none;
  }

  &:disabled {
    opacity: .5;
  }
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
  &:focus {
    box-shadow: 0 0 2.5px 2.5px #7DADD9;
    outline: 0;
  }
}
.tg-button--beta-link {
  border: 1px solid rgba(0, 0, 0, .3);
  padding: 2px;

  .tg-button__icon {
    height: 20px;
    width: 20px;
    margin: 0;
  }
  .tg-button__label {
    @include text-button-sm;
    padding: 0 8px 0 5px;
  }

  &:hover {
    border: 1px solid black;
  }
}
.tg-button--black {
  background-color: rgba(0, 0, 0, .78);
  //border-color: rgba(0, 0, 0, .78);

  .tg-button__label {
    @include text-button-centered-reversed;
    color: white;
  }

  &:disabled {
    opacity: .3;
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      background-color: black;
    }
  }
}
.tg-button--blue {
  background-color: $c-blue;
  border: 1px solid #fff;

  .tg-button__label {
    @include text-button-centered-reversed;
    color: white;
  }
}
.tg-button--orange {
  background-color: #FF8563;
  padding: 12px;

  .tg-button__label {
    color: rgba(0, 0, 0, .78);
  }

  @include media-breakpoint-up(xl) {
    &:hover {
      background-color: #FFAD7E;

      .tg-button__label {
        color: black;
      }
    }
  }
}
.tg-button--pill {
  border-radius: 100px;
}
.tg-button--pill-brandbar {
  vertical-align: middle;
  padding: 3.5px 5.25px;
  border: 0;
  border-radius: 6px;
  background-color: white;

  .tg-button__label {
    line-height: 9px;
    font-size: 9px;
    font-weight: 700;
    vertical-align: -webkit-baseline-middle;
  }
}
.tg-button--small {
  padding: 2px 5px; //16x28

  .tg-button__label {
    @include text-txt-XS;
    font-size: 10px;
  }
}
.tg-button--white {
  background-color: white;
  border: 1px solid rgba(132, 187, 198, .78);

  .tg-button__label {
    color: $c-blue;
  }
}
.tg-button--white-ghost {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, .3);

  .tg-button__label {
    font-weight: normal;
    color: $c-blue;
  }
}
.tg-button--yellow {
  border: 1px solid rgba(0, 0, 0, .30);
  background-color: #ffc828;

  .tg-button__label {
    @include text-button-centered;
    color: black;
  }
}
//
//tablet-
@include media-breakpoint-down(lg) {
  .tg-button--beta-link {
    position: absolute;
    right: 60px;
    margin-top: 2px;
    //top: 50%;
    //transform: translateY(-50%);
  }
}
//
//desktop+
@include media-breakpoint-up(lg) {
  .tg-button--pill-brandbar {
    margin-right: 5px;
  }
}
