.tag {
  margin:  0 0 10px;
  display: inline-block;
}
.tag__link {
  font-size:        12px;
  line-height:      18px;
  font-weight:      500;
  color:            $c-blue-link;
  padding:          4px 8px;
  background-color: rgba(206, 234, 242, .78);
  border-radius:    100px;
}
