.page-getinvolved {
}
.page-getinvolved__contact {
  margin-top: 50px;

  .m-contacts__title {
    text-transform: capitalize;
  }
}
.page-getinvolved__contact-col {
  flex:           1 1 100%;
  padding-bottom: 16px;

  &:first-child {
    padding-bottom: 33px;
  }
}
.page-getinvolved__m-contacts {
  margin: 0;
}
//
//tablet+
@include media-breakpoint-up(sm) {
  .page-getinvolved__contact-col {
    flex:           1 1 50%;
    padding-bottom: 0;

    &:first-child {
      padding-bottom: 0;
    }
  }
}
