.carouselslide {
  //position: absolute;
  top:    0;
  //left:     0;
  width:  100%;
  height: 100%;
}
.carouselslide__content {
  max-width:  770px;
  text-align: left;
  padding:    100px 15px 30px;

  @include media-breakpoint-up(md) {
    //padding:    30px 15px;
    padding: 135px 15px 60px;
    margin:  0 auto;
  }
}
.carouselslide__description {
  @include text-h2;

  @include media-breakpoint-up(md) {
    @include text-display-bold;
  }
}
.carouselslide__search {
  @include media-breakpoint-up(md) {
    margin: 52px 0 0;
  }
}
.carouselslide__search-description {
  @include text-txt-M;
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    margin-bottom: 8px;
  }
}
.carouselslide__search-container {
  position: relative;
  cursor:   pointer;
}
.carouselslide__search-cover {
  position: absolute;
  top:      0;
  left:     0;
  right:    0;
  bottom:   0;
  width:    100%;
  height:   100%;
}
