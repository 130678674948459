.page-getinvolvedfeedback {

  label {
    @include text-input-label;
  }
  .form-control {
    @include text-input-text;

    &::placeholder, &::disabled {
      @include text-input-hint;
    }
  }
  .form-check-label {
    @include text-txt-S;
  }
  textarea {
    min-height: 160px;
  }
  input, textarea {
    @include input-base;
  }
  .select {
    margin-bottom: 15px;
  }
  input[type=checkbox] {
    @include input-checkbox;
  }
}
.page-getinvolvedfeedback__intro {
  margin: 0 0 48px;
}
.page-getinvolvedfeedback__intro-title {
  @include text-h1;
  margin-bottom: 20px;
}
.page-getinvolvedfeedback__intro-subtext {
  @include text-txt-L;
}
.page-getinvolvedfeedback__form {
  margin: 0 0 32px;
}
.page-getinvolvedfeedback__submit {
  margin-top: 24px;
}
.page-getinvolvedfeedback__i-want-to-copy {
  margin: 15px 0;
}
//
//tablet+
@include media-breakpoint-up(md) {
  .page-getinvolvedfeedback__form {
    margin: 0 100px 64px;
  }
}
