.m-listnav {
}
.m-listnav__title {
  @include text-h5;
  display: block;
  text-transform: uppercase;
  margin: 0 0 16px 0;

  //white-space:    nowrap;
  //overflow:       hidden;
  //text-overflow:  ellipsis;
  word-wrap: break-word;
}
.m-listnav__description {
  @include text-txt-XS-lighter;
  margin: -12px 0 12px;

  word-wrap: break-word;

  @include media-breakpoint-up(xl) {
    margin: -12px 0 20px;
  }
}
.m-listnav__list {
  width: 100%;
}
.m-listnav--horizontal {
  .m-listnav__title {
    display: inline-block;
    vertical-align: middle;
  }
  .m-listnav__list {
    width: auto;
    display: inline-block;
    vertical-align: middle;
  }
  .navigationlink {
    display: inline-block;
    vertical-align: middle;
  }
}
.m-listnav--footer-social {
  .navigationlink__title {
    display: none;
  }
}
.m-listnav--blue-outline-pills {
  @include navigationlink--blue-outline;

  .m-listnav__list {
    margin: 16px 0;
  }
  .navigationlink__link {
    font-size: 12px;
  }
}
.m-listnav--columns-two {
  .m-listnav__list {
    display: flex;
    flex-direction: column;
    //height: 114px;
    flex-wrap: wrap;
  }

  //.navigationlink {
  //  width:   50%;
  //  display: inline-block;
  //}
}
.m-listnav--hide-buttons {
  .m-listnav__list {
    display: none;
  }
}
.m-listnav--page-content {
  margin: 48px 0 0;

  .m-listnav__title {
    @include text-h3;
    text-transform: none;
  }
  .navigationlink__link {
    @include text-links;
  }
}
.m-listnav--page-search-results {
  .m-listnav__list {
    padding: 0 0 0 16px;
  }
  .navigationlink__description {
    display: none;
  }
}
//
//desktop+
@include media-breakpoint-up(xl) {
  .m-listnav--footer {
    .navigationlink__link {
      &:hover {
        color: white;
        text-decoration: underline;

        .navigationlink__title {
          border-color: white;
        }
      }
    }
  }
}
