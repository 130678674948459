.news {
  .button-input {
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.78);

    &.search-input-open {
      border-bottom: 1px solid black;
    }

    .input {
      border: 0;

      &:focus {
        outline: none;
      }
    }
  }
}
.news__filter-button {
  display:     none;
  font-size:   16px;
  font-weight: 500;
  color:       rgba(80, 133, 166, 1) !important;
}
.news__listing-item-col {
  &:last-child {
    .news__news-item {
      border-bottom: 0;
    }
  }
}
.news__news-item.news-item {
  padding-bottom: 15px;
  margin-top:     15px;
  border-bottom:  1px solid rgba(0, 0, 0, .15);

  .news-item__description {
    padding-top: 10px;
  }
  .news-item__title {
    padding-bottom: 0;
  }
}
//
// < Mobile
@include media-breakpoint-down(xs) {
  .news__filter-button {
    display: block;
    float:   right;
  }
  .news__results {
    padding-top: 24px;
  }
}
