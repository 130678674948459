.m-breadcrumbs {
  margin: 70px 0 15px;
  margin: 0;
  visibility: hidden;
  height: 85px;
  overflow: hidden;
}
.m-breadcrumbs__crumbs {
  line-height: 18px;
}
.m-breadcrumbs__crumb {
  @include text-txt-XS;
  line-height: 18px;
}
a.m-breadcrumbs__crumb {
  color: $c-black-transparent;

  &:hover {
    color: $c-black-transparent;
  }
}
.m-breadcrumbs__crumb--inactive {
  color: rgba(0, 0, 0, .34);
}
.m-breadcrumbs--project-details {
  height: 85px;
}
//
//tablet+
@include media-breakpoint-up(md) {
  .m-breadcrumbs {
    height: auto;
    visibility: visible;
    margin: 85px 0 15px;
  }
  .m-breadcrumbs--project-details {
    margin-top: 85px;
  }
}
