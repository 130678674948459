.page-projects__intro {
  .m-intro__description {
    display: none;
  }
}
//
//tablet+
@include media-breakpoint-up(md) {
  .page-projects__intro {
    margin-bottom: 90px;

    .m-intro__description {
      display: block;
    }
    .m-intro__title {
      margin-top: 25px;
    }
  }
}
