.m-footer {
  background-color: $c-blue;
  padding: 30px 0 25px;
  flex-shrink: 0;

  /* todo: this is included inside m-footer because it overrides it; unsure if it should be a modifier of m-listnav, m-listnav--footer, m-listnav--footer-bold, etc.? */
  .m-listnav {
    .m-listnav__title {
      @include text-footer-section;
      color: white;
    }

    &.m-listnav--page-search-results {
      .m-listnav__title {
        color: rgba(0, 0, 0, 0.78);
      }
    }
  }
  .navigationlink {
    @include text-txt-XS;
    color: $c-white-transparent;
  }

  .m-listnav--page-search-results {
    .navigationlink {
      color: rgba(0, 0, 0, 0.78);
    }
  }

  .navigationlink__description {
    color: $c-white-transparent;
    margin: 16px 0 0;
  }
  .navigationlink__link {
    color: $c-white-transparent;
  }
  .m-listnav--page-search-results {
    .navigationlink__link {
      color: rgba(0, 0, 0, 0.78);
    }
  }
  .m-listnav--horizontal {
    margin-top: 16px;

    .m-listnav__title {
      margin: 0 30px 0 0;
    }
    .navigationlink {
      margin: 0 20px 0 0;
    }

    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
}
.m-footer__contact-description {
  p {
    @include text-txt-XS;
    line-height: 1em;
    color: $c-white-transparent;
  }
}
.m-footer__site-search {
  margin-top: 20px;
  margin-bottom: 25px;

  .button-input {
    flex: 1 1;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .site-search__results {
    cursor: pointer;
  }
}
.m-footer__bot-left-text {
  @include text-txt-R;
  color: $c-white-transparent;
}
.m-footer__legal-text-col {
  margin-top: 8px;

  @include media-breakpoint-up(lg) {
    text-align: right;
    margin-top: 0;
  }
}
.m-footer__legal-text {
  @include text-txt-XS;
  color: $c-white-transparent;
}
.m-footer__row-top {
  margin-bottom: 0px;

  @include media-breakpoint-up(md) {
    margin-bottom: 33px;
  }
}
//
//desktop+
@include media-breakpoint-up(xl) {
  .m-footer {
    padding: 40px 0 25px;
  }
}
