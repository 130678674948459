.m-programmapad {

}
.m-programmapad__container {
  background-image: url(../img/program-map-ad.jpg);
  background-size: contain;
  border: 1px solid rgba(132, 187, 198, 0.78);
  position: relative;
  max-height: 350px;
  max-width: 440px;
}
.m-programmapad__content {
  position: absolute;
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  padding-left: 24px;
}
.m-programmapad__sizer {
  padding-bottom: 79.4%;
}
.m-programmapad__title {
  @include text-h1;
  font-size: 18px;
  font-weight: 500;
  color: #5085a6;
  line-height: 1.28;
  margin-bottom: 8px;
}
.m-programmapad__description {
  @include text-txt-L;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  max-width: 740px;
  margin: 0;
}
