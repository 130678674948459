.page-content__content.notfoundpage {
  margin-top: 80px;
}
.notfoundpage__img {
  display: none;
  max-width: 100%;
}
.notfoundpage__img--mobile {
  display: block;
}
.notfoundpage__text {
  @include text-base;
  color: #182a3e;
  font-size: 20px;
  font-family: 'Roboto Condensed';
  font-weight: 700;
  margin: 0 8px;
  max-width: 359px; //mobile 404 gif is 375w, has 8px margins
  padding: 2px;

  a {
    @include text-base;
    color: #182a3e;
    font-size: 20px;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    color: #fb844a;
  }
}

//
//tablet+
@media screen and (min-width: 406px) {
  .notfoundpage__img {
    display: block;
    margin: 20px 0 10px;
    max-width: 100%;
  }
  .notfoundpage__img--mobile {
    display: none
  }
  .notfoundpage__text {
    margin: 0 15px;
    max-width: 698px; //404 gif is 728w, has 15px margins
    padding: 2px;
  }
}
