.searh__results {
  @include media-breakpoint-down(xs) {
    padding-top: 24px;
  }
}

.search-page {
  .button-input {
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.78);
    padding-bottom: 1px;

    &.search-input-open {
      border-bottom: 1px solid black;
    }

    .input {
      border: 0;
      font-size: 48px;
      font-weight: 500;
      padding-left: 0;

      &:focus {
        outline: none;
      }
    }
  }
}
