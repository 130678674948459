.list-card {
  position: relative;

  .navigationlink {
    display: block;
  }
}
.list-card__background {
  padding-bottom: 62.96%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.list-card__text {
  position: relative;
  padding: 20px 25px;
  flex: 1 1 0;
}
.list-card__description {
  //@include text-txt-S;
  @include text-txt-XS;
  margin: 5px 0 0;
}
.list-card__link {
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  border: 1px solid #84BBC6;
  background-color: white;
  overflow: hidden;
  height: auto;

  &:hover {
    text-decoration: none;

    &.superlink {
      .list-card__title {
        text-decoration: underline;
      }
      .navigationlink {
        .navigationlink__title {
          border-color: black;
        }
      }
    }
  }
}
.list-card__opens-tab {
  background-image: url(../img/icons/icon-link.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  top: 24px;
  right: 12px;
  position: absolute;
}
.list-card__title {
  @include text-h4;
  margin-bottom: 3px;
}
.list-card--get-involved {
  flex: 0 0 270px;
  box-shadow: 1px 3px 24px rgba(0, 0, 0, .1);
  margin: 8px;

  .list-card__background {
    padding-bottom: 107.4%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .list-card__link {
    background-image: none;
  }
}
.list-card--wide {
  .list-card__background {
    background-image: url(../img/card-bg-mobile.png);
    min-height: 174px;
  }
  .list-card__link {
    background: white;
    display: flex;
    flex-direction: column;
  }
  .list-card__opens-tab {
    top: 27px;
  }
  .list-card__text {
    flex: 0 1 0;
  }
  .list-card__title {
    @include text-card-title;
    color: black;
    margin: 0;
  }
}
//
//tablet+
@include media-breakpoint-up(sm) {
  .list-card__link {
    height: 358px;
  }
  .list-card--get-involved {
    .list-card__link {
      height: 436px;
    }
  }
}
