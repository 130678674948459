.google-translate {
  display:        inline-block;
  vertical-align: middle;

  .goog-te-gadget {
    display:   block;
    height:    .875rem;
    font-size: 0;
    color:     transparent;
    display:   none;

    > span {
      display: none;
    }
    > div {
      display: block;
      height:  .875rem;
    }
    .goog-te-combo {
      @include text-google-translate;
      -webkit-appearance:  none;
      -moz-appearance:     none;
      appearance:          none;
      background-color: #10293f;
      border:              none;
      color:               white;
      background-image:    url(../img/icon-chevron-white-down.png);
      background-size:     12px auto;
      background-repeat:   no-repeat;
      background-position: center right;
      margin:              0;
      display:             none;

      &:first-child {
        display: block;
      }
    }

    /* CAUTION: IE hackery ahead */
    select::-ms-expand {
      display: none; /* remove default arrow on ie10 and ie11 */
    }

    /* target Internet Explorer 9 to undo the custom arrow */
    @media screen and (min-width: 0 \0
    ) {
      select {
        background: none \9;
        padding:    5px \9;
      }
    }

    &:first-child {
      display: block;
    }
  }
}
