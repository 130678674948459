.lightbox {
  position:         fixed;
  top:              0;
  left:             0;
  bottom:           0;
  right:            0;
  width:            100%;
  height:           100%;
  background-color: rgba(0, 0, 0, .2);
  z-index:          -1;
  opacity:          0;
  transition:       z-index 0s, opacity #{$transition-fast};

  .carousel {
    .slide {
      background: white;
    }
  }
  .carousel.carousel-slider {
    .control-arrow {
      opacity: 1;
      background: none;

      &:before {
        background-image:  url(../img/icons/icon-arrow-left-white.png);
        border:            0;
        width:             17px;
        height:            30px;
        background-size:   contain;
        background-repeat: no-repeat;
      }
      &.control-next {
        &:before {
          background-image: url(../img/icons/icon-arrow-right-white.png);
        }
      }
    }
  }
}
.lightbox__box {
  padding:       40px 15px;
  background:    white;
  border-radius: 4px;
  position:      absolute;
  top:           50%;
  left:          50%;
  transform:     translate3d(-50%, -50%, 0);
  width:         100%;
  max-height:    100%;
}
.lightbox__close {
  width:               55px;
  height:              40px;
  display:             block;
  background-color:    transparent;
  background-image:    url(../img/icons/icon-close.svg);
  background-size:     20px auto;
  background-position: center center;
  background-repeat:   no-repeat;
  font-size:           0;
  color:               transparent;
  border:              0;
  padding:             0;
  top:                 0;
  right:               0;
  position:            absolute;
}
.lightbox__media-caption {
  @include text-txt-XS-lighter;
  text-align: left;
  margin-top: 22px;
}
.lightbox__media-slide {
  position: relative;

  iframe {
    width:  100%;
    height: 100%;
  }
}
.lightbox__media-spacer {
  padding-top: 65.2%;
}
.lightbox__media-slide-imagery {
  overflow:   hidden;
  position:   relative;
  max-height: 70vh;
}
.carousel .slide .lightbox__media-imagery-container {
  position:   absolute;
  top:        0;
  right:      0;
  bottom:     0;
  left:       0;
  width:      100%;
  height:     100%;
  max-height: 70vh;
}
.carousel .slide .lightbox__media-imagery {
  height:     auto;
  width:      auto;
  max-width:  100%;
  max-height: 100%;
}
.lightbox--is-open {
  z-index: 2;
  opacity: 1;
}
//
//tablet+
@include media-breakpoint-up(md) {
  .lightbox__box {
    padding:   70px 50px 40px;
    width:     78%;
    max-width: 1700px;
    max-width: 1125px;
  }
  .lightbox__close {
    width:  55px;
    height: 70px;
  }
}
