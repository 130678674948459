.pagination-container {
  display: flex;
  justify-content: center;

  .pagination {
    margin-top: 8px;
  }

  .page-link {
    color: rgba(0, 0, 0, 0.78);
    border: 0;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;

    &:focus {
      box-shadow: none;
    }

    .button__icon {
      max-width: 16px;
      
      &.button__left {
        transform: scaleX(-1);
      }
    }
  }

  .page-item {
    padding-left: 2px;
    padding-right: 2px;

    .arrow-button {
      border: 1px solid rgba(132, 187, 198, 0.78);
      padding: 7px;
    }

    &:first-child {
      padding-right: 10px;
    }

    &:last-child {
      padding-left: 10px;
    }

    &.disabled-button {
      .arrow-button {
        border: 1px solid rgba(132, 187, 198, 0.35);
      }

      .page-link {
        color: rgba(0, 0, 0, 0.35);
        pointer-events: none;

        .button__icon {
          opacity: .35;
        }
      }
    }

    &.active {

      .page-link {
        background-color: #FF8563;
        border-color: #FF8563;
        
        color: rgba(0, 0, 0, 0.78);
      }
    }
  }
}