.page--hamburger-is-open {
  .m-brandbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
}
//
//desktop+
@include media-breakpoint-up(lg) {
  .page--hamburger-is-open {
    .m-brandbar {
      position: static;
    }
  }
}
