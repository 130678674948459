.page-content-detail {
  .list-tags {
    display: flex;
    align-items: center;
    .navigationlink {
      padding: 0 10px;
      margin-bottom: 0;
    }
  }

  .m-listnav--page-content {
    .m-listnav__title {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.25;
      text-transform: uppercase;
    }

    .navigationlink__link {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      color: #5085a6;
    }
  }

  .document-container--page-content {
    margin-top: 24px;
    .m-document-title {
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.78);
      line-height: 1.25;
      margin-bottom: 24px;
    }

    .m-listnav__title {
      font-size: 16px;
      line-height: 1.5;
      text-transform: capitalize;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.78);
    }

    .navigationlink__link {
      font-size: 16px;
      font-weight: 500;
      color: #5085a6;
      line-height: 1.5;
    }
  }
}
